import TextField from "@mui/material/TextField";
import React, { useState} from "react";
import { getLocalizedText, resolvePersianAndArabicNumbers } from "../../helpers";
import LoadingButton from "../global-components/loading-btn";
import { useAuth } from "../../helpers/context";
import { CLASSIC2_TEMPLATES, CLASSIC_TEMPLATES, DARK_TEMPLATES, MODERN2_TEMPLATES, MODERN_TEMPLATES } from "../../constants";
import { useTranslation } from "react-i18next";
import T04WhyUS from "../pro/template-04/why-us";
import T05WhyUS from "../pro/template-05/why-us";
import T06WhyUS from "../pro/template-06/why-us";
import { translateText } from "../../api/userApi";

const WhyUsSection = (props) => {
  const [Data, setData] = useState({
    ...props.data,
    oldTitle1: props.data.title1,
    oldContent1: props.data.content1,
    oldTitle2: props.data.title2,
    oldContent2: props.data.content2,
    oldTitle3: props.data.title3,
    oldContent3: props.data.content3,
    oldDesc: props.data.desc
  });
  const { templateId, autoTranslate } = useAuth();
  const { t, i18n } = useTranslation();
  const [isSubmittingData, setIsSubmittingData] = useState(false);

  return (
    <div className={`whyus-section ${props.isEdit ? 'admin' : ''}`}>
      <div className='row'>
        <div className='col-12'>
          {props.isEdit ? (
            <>
              <TextField
                label={t("Tagline")}
                variant='outlined'
                fullWidth
                multiline
                rows={3}
                inputProps={{ maxLength: 500 }}
                InputLabelProps={{ shrink: true }}
                className='mt-4 text-start'
                value={getLocalizedText(Data.desc, i18n.language)}
                onChange={(v) => {
                  let arabicPart = getLocalizedText(Data.desc, "ar");
                  let englishPart = getLocalizedText(Data.desc, "en");
                  if (i18n.language === "ar")
                    arabicPart = v.currentTarget.value?.length >= 500 ? v.currentTarget.value?.substring(0, 500) : v.currentTarget.value;
                  else
                    englishPart = v.currentTarget.value?.length >= 500 ? v.currentTarget.value?.substring(0, 500) : v.currentTarget.value;
                  let text = (arabicPart + (englishPart?.length > 0 ? ('~@~' + englishPart) : ""));
                  setData({ ...Data, desc: resolvePersianAndArabicNumbers(text) });
                }}
              />
              <h6 className='rightalign mt-2 mb-0'>
                {Data.desc
                  ? parseInt(getLocalizedText(Data.desc, i18n.language)?.length) + "/500"
                  : "0/500"}
              </h6>
              <div className="row mt-5 px-2 px-sm-5 pb-2 justify-content-center">
                <div className="col-12 col-md-6 col-lg-4 text-center px-md-3">
                 
                  <TextField
                    label={`${t("TitleOfTheFeature")} 1`}
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    className='mt-4'
                    value={getLocalizedText(Data.title1, i18n.language)}
                    onChange={(v) => {
                      let arabicPart = getLocalizedText(Data.title1, "ar");
                      let englishPart = getLocalizedText(Data.title1, "en");
                      if (i18n.language === "ar")
                        arabicPart = v.currentTarget.value;
                      else
                        englishPart = v.currentTarget.value;
                      let text = (arabicPart + (englishPart?.length > 0 ? ('~@~' + englishPart) : ""));
                      setData({ ...Data, title1: resolvePersianAndArabicNumbers(text) });
                    }}
                  />
                  <TextField
                    label={`${t("DescriptionOfTheFeature")} 1`}
                    variant='outlined'
                    fullWidth
                    multiline
                    InputLabelProps={{ shrink: true }}
                    rows={4}
                    inputProps={{ maxLength: 200 }}
                    className='mt-4 multiline'
                    value={getLocalizedText(Data.content1, i18n.language)}
                    onChange={(v) => {
                      let arabicPart = getLocalizedText(Data.content1, "ar");
                      let englishPart = getLocalizedText(Data.content1, "en");
                      if (i18n.language === "ar")
                        arabicPart = v.currentTarget.value?.length >= 300 ? v.currentTarget.value?.substring(0, 300) : v.currentTarget.value;
                      else
                        englishPart = v.currentTarget.value?.length >= 300 ? v.currentTarget.value?.substring(0, 300) : v.currentTarget.value;
                      let text = (arabicPart + (englishPart?.length > 0 ? ('~@~' + englishPart) : ""));
                      setData({ ...Data, content1: resolvePersianAndArabicNumbers(text) });
                    }}
                  />
                  <h6 className='rightalign mt-2 mb-0'>
                    {Data.content1
                      ? parseInt(getLocalizedText(Data.content1, i18n.language)?.length) + "/200"
                      : "0/200"}
                  </h6>
                </div>
                <div className="col-12 col-md-6 col-lg-4 text-center px-md-3">
                  <TextField
                    label={`${t("TitleOfTheFeature")} 2`}
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    className='mt-4'
                    value={getLocalizedText(Data.title2, i18n.language)}
                    onChange={(v) => {
                      let arabicPart = getLocalizedText(Data.title2, "ar");
                      let englishPart = getLocalizedText(Data.title2, "en");
                      if (i18n.language === "ar")
                        arabicPart = v.currentTarget.value;
                      else
                        englishPart = v.currentTarget.value;
                      let text = (arabicPart + (englishPart?.length > 0 ? ('~@~' + englishPart) : ""));
                      setData({ ...Data, title2: resolvePersianAndArabicNumbers(text) });
                    }}
                  />
                  <TextField
                    label={`${t("DescriptionOfTheFeature")} 2`}
                    variant='outlined'
                    fullWidth
                    multiline
                    InputLabelProps={{ shrink: true }}
                    rows={4}
                    inputProps={{ maxLength: 200 }}
                    className='mt-4 multiline'
                    value={getLocalizedText(Data.content2, i18n.language)}
                    onChange={(v) => {
                      let arabicPart = getLocalizedText(Data.content2, "ar");
                      let englishPart = getLocalizedText(Data.content2, "en");
                      if (i18n.language === "ar")
                        arabicPart = v.currentTarget.value?.length >= 300 ? v.currentTarget.value?.substring(0, 300) : v.currentTarget.value;
                      else
                        englishPart = v.currentTarget.value?.length >= 300 ? v.currentTarget.value?.substring(0, 300) : v.currentTarget.value;
                      let text = (arabicPart + (englishPart?.length > 0 ? ('~@~' + englishPart) : ""));
                      setData({ ...Data, content2: resolvePersianAndArabicNumbers(text) });
                    }}
                  />
                  <h6 className='rightalign mt-2 mb-0'>
                    {Data.content2
                      ? parseInt(getLocalizedText(Data.content2, i18n.language)?.length) + "/200"
                      : "0/200"}
                  </h6>
                </div>
                <div className="col-12 col-md-6 col-lg-4 text-center px-md-3">
                  
                  <TextField
                    label={`${t("TitleOfTheFeature")} 3`}
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    className='mt-4'
                    value={getLocalizedText(Data.title3, i18n.language)}
                    onChange={(v) => {
                      let arabicPart = getLocalizedText(Data.title3, "ar");
                      let englishPart = getLocalizedText(Data.title3, "en");
                      if (i18n.language === "ar")
                        arabicPart = v.currentTarget.value;
                      else
                        englishPart = v.currentTarget.value;
                      let text = (arabicPart + (englishPart?.length > 0 ? ('~@~' + englishPart) : ""));
                      setData({ ...Data, title3: resolvePersianAndArabicNumbers(text) });
                    }}
                  />
                  <TextField
                    label={`${t("DescriptionOfTheFeature")} 3`}
                    variant='outlined'
                    fullWidth
                    inputProps={{ maxLength: 200 }}
                    InputLabelProps={{ shrink: true }}
                    multiline
                    rows={4}
                    className='mt-4 multiline'
                    value={getLocalizedText(Data.content3, i18n.language)}
                    onChange={(v) => {
                      let arabicPart = getLocalizedText(Data.content3, "ar");
                      let englishPart = getLocalizedText(Data.content3, "en");
                      if (i18n.language === "ar")
                        arabicPart = v.currentTarget.value?.length >= 300 ? v.currentTarget.value?.substring(0, 300) : v.currentTarget.value;
                      else
                        englishPart = v.currentTarget.value?.length >= 300 ? v.currentTarget.value?.substring(0, 300) : v.currentTarget.value;
                      let text = (arabicPart + (englishPart?.length > 0 ? ('~@~' + englishPart) : ""));
                      setData({ ...Data, content3: resolvePersianAndArabicNumbers(text) });
                    }}
                  />
                  <h6 className='rightalign mt-2 mb-0'>
                    {Data.content3
                      ? parseInt(getLocalizedText(Data.content3, i18n.language)?.length) + "/200"
                      : "0/200"}
                  </h6>
                </div>
              </div>
              <div className='mt-3 '>
                <LoadingButton
                  label={t("SaveChanges")}
                  classes="primary-btn px-3 m-auto"
                  loading={props.loading || isSubmittingData}
                  handleClick={async () => {
                    setIsSubmittingData(true);

                    let oldArabicTitle1 = getLocalizedText(Data.oldTitle1, "ar"),
                      newArabicTitle1 = getLocalizedText(Data.title1, "ar"),
                      newTitle1 = Data.title1,
                      oldArabicTitle2 = getLocalizedText(Data.oldTitle2, "ar"),
                      newArabicTitle2 = getLocalizedText(Data.title2, "ar"),
                      newTitle2 = Data.title2,
                      oldArabicTitle3 = getLocalizedText(Data.oldTitle3, "ar"),
                      newArabicTitle3 = getLocalizedText(Data.title3, "ar"),
                      newTitle3 = Data.title3,
                      oldArabicContent1 = getLocalizedText(Data.oldContent1, "ar"),
                      newArabicContent1 = getLocalizedText(Data.content1, "ar"),
                      newContent1 = Data.content1,
                      oldArabicContent2 = getLocalizedText(Data.oldContent2, "ar"),
                      newArabicContent2 = getLocalizedText(Data.content2, "ar"),
                      newContent2 = Data.content2,
                      oldArabicContent3 = getLocalizedText(Data.oldContent3, "ar"),
                      newArabicContent3 = getLocalizedText(Data.content3, "ar"),
                      newContent3 = Data.content3,
                      oldArabicDesc = getLocalizedText(Data.oldDesc, "ar"),
                      newArabicDesc = getLocalizedText(Data.desc, "ar"),
                      newDesc = Data.desc;
                      
                    if (i18n.language === "ar" && autoTranslate === "1") {
                      if (oldArabicTitle1 !== newArabicTitle1 && newArabicTitle1?.trim() !== "") {
                        let newEnglishTitle1 = await translateText(newArabicTitle1);
                        newTitle1 = newArabicTitle1 + '~@~' + newEnglishTitle1;
                      }
                      if (oldArabicTitle2 !== newArabicTitle2 && newArabicTitle2?.trim() !== "") {
                        let newEnglishTitle2 = await translateText(newArabicTitle2);
                        newTitle2 = newArabicTitle2 + '~@~' + newEnglishTitle2;
                      }
                      if (oldArabicTitle3 !== newArabicTitle3 && newArabicTitle3?.trim() !== "") {
                        let newEnglishTitle3 = await translateText(newArabicTitle3);
                        newTitle3 = newArabicTitle3 + '~@~' + newEnglishTitle3;
                      }
                      if (oldArabicContent1 !== newArabicContent1 && newArabicContent1?.trim() !== "") {
                        let newEnglishContent1 = await translateText(newArabicContent1);
                        newEnglishContent1 = newEnglishContent1?.length >= 200 ? newEnglishContent1?.substring(0, 200) : newEnglishContent1;
                        newContent1 = newArabicContent1 + '~@~' + newEnglishContent1;
                      }
                      if (oldArabicContent2 !== newArabicContent2 && newArabicContent2?.trim() !== "") {
                        let newEnglishContent2 = await translateText(newArabicContent2);
                        newEnglishContent2 = newEnglishContent2?.length >= 200 ? newEnglishContent2?.substring(0, 200) : newEnglishContent2;
                        newContent2 = newArabicContent2 + '~@~' + newEnglishContent2;
                      }
                      if (oldArabicContent3 !== newArabicContent3 && newArabicContent3?.trim() !== "") {
                        let newEnglishContent3 = await translateText(newArabicContent3);
                        newEnglishContent3 = newEnglishContent3?.length >= 200 ? newEnglishContent3?.substring(0, 200) : newEnglishContent3;
                        newContent3 = newArabicContent3 + '~@~' + newEnglishContent3;
                      }
                      if (oldArabicDesc !== newArabicDesc && newArabicDesc?.trim() !== "") {
                        let newEnglishDesc = await translateText(newArabicDesc);
                        newEnglishDesc = newEnglishDesc?.length >= 500 ? newEnglishDesc?.substring(0, 500) : newEnglishDesc;
                        newDesc = newArabicDesc + '~@~' + newEnglishDesc;
                      }
                    }
                    props.saveDetails({
                      data: {
                        ...Data,
                        title1: newTitle1,
                        content1: newContent1,
                        title2: newTitle2,
                        content2: newContent2,
                        title3: newTitle3,
                        content3: newContent3,
                        desc: newDesc
                      }
                    });
                    setIsSubmittingData(false);
                  }}
                />
              </div>
            </>
          ) : CLASSIC_TEMPLATES.includes(+templateId) ? <T04WhyUS data={Data} /> :
            CLASSIC2_TEMPLATES.includes(+templateId) ? <T05WhyUS data={Data} /> :
              MODERN2_TEMPLATES.includes(+templateId) ? <T06WhyUS data={Data} /> :
                (
                  <> {
                    getLocalizedText(Data.title1, i18n.language, false)?.trim() === "" && getLocalizedText(Data.title2, i18n.language, false)?.trim() === "" &&
                      getLocalizedText(Data.title3, i18n.language, false)?.trim() === "" &&
                      getLocalizedText(Data.content1, i18n.language, false)?.trim() === "" && getLocalizedText(Data.content2, i18n.language, false)?.trim() === ""
                      && getLocalizedText(Data.content3, i18n.language, false)?.trim() === "" ?
                      <></> : (
                        <>
                          <div className="col-12 justify-content-center align-items-center flex-column d-flex" >
                            <span className="section-prefix">{t("OurAdvantages")}</span>
                            <h2 className='sec-title'>{t("WhatMakesUsDifferentFromOthers")}</h2>
                            <p className="sec-desc">{getLocalizedText(Data.desc, i18n.language, false)}</p>
                          </div>
                          <div className={`row features-row mt-5`}>
                            {(getLocalizedText(Data.title1, i18n.language, false)?.trim() !== "" || getLocalizedText(Data.content1, i18n.language, false)?.trim() !== "") && (
                              <div className="feature col-12 col-lg-4 col-xl-3">
                                <div className="feature-icon">{[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? '.01' : '1'}</div>
                                <h5 className="feature-title">{getLocalizedText(Data.title1, i18n.language, false)}</h5>
                                <p className="feature-desc">{getLocalizedText(Data.content1, i18n.language, false)}</p>
                              </div>
                            )}
                            {(getLocalizedText(Data.title2, i18n.language, false)?.trim() !== "" || getLocalizedText(Data.content2, i18n.language, false)?.trim() !== "") && (
                              <div className="feature col-12 col-lg-4 col-xl-3">
                                <div className="feature-icon">{[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? '.02' : '2'}</div>
                                <h5 className="feature-title">{getLocalizedText(Data.title2, i18n.language, false)}</h5>
                                <p className="feature-desc">{getLocalizedText(Data.content2, i18n.language, false)}</p>
                              </div>
                            )}
                            {(getLocalizedText(Data.title3, i18n.language, false)?.trim() !== "" || getLocalizedText(Data.content3, i18n.language, false)?.trim() !== "") && (
                              <div className="feature col-12 col-lg-4 col-xl-3">
                                <div className="feature-icon">{[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? '.03' : '3'}</div>
                                <h5 className="feature-title">{getLocalizedText(Data.title3, i18n.language, false)}</h5>
                                <p className="feature-desc">{getLocalizedText(Data.content3, i18n.language, false)}</p>
                              </div>
                            )}
                          </div>
                        </>
                      )
                  }
                  </>
                )}
        </div>
      </div>
    </div>
  );
}

export default WhyUsSection;
