import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle, Fab, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Pagination, Select } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../helpers/context";
import { getMyProperties, getPropertyTypesForCategory, getVipOnly, saveVip } from "../../../api/propertyApi";
import PropertyCardForAdmin from "../../shared/properties/components/property-card-for-admin";
import LoadingData from "../../../components/global-components/loading-data";
import NoData from "../../../components/global-components/no-data";
import SubHeader from "../../../components/global-components/sub-header";
import { Plus } from "../../../constants/icons";
import LoadingButton from "../../../components/global-components/loading-btn";
import { openDeleteModal } from "../../../helpers";

const pageSize = 12;

const MyListings = (props) => {
  const [Properties, setProperties] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [openNewADsDialog, setOpenNewADsDialog] = useState(false);
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [Settings, setSettings] = useState({
    userId: props.userId,
    page: searchParams.get('page') || "1",
    pageSize: searchParams.get('pageSize') || "50",
  });
  const [selectedProperties, setSelectedProperties] = React.useState([]);
  const [propertyType, setPropertyType] = React.useState('-1');
  const [loadingProperties, setLoadingProperties] = React.useState(true);
  const [propertiesForAdd, setPropertiesForAdd] = React.useState([]);
  const [totalCountForAdd, setTotalCountForAdd] = React.useState(0);
  const [TotalCount, setTotalCount] = useState(0);
  const [loadingButton, setLoadingButton] = React.useState(false);
  const { token } = useAuth();
  const [page, setPage] = React.useState(1);
  const [propertyTypes, setPropertyTypes] = React.useState([]);
  const [loadingPublishButton, setLoadingPublishButton] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState();

  React.useEffect(() => {
    getPropertyTypesForCategory({
      category: '1,2',
      language: i18n.language === 'ar' ? '0' : '1',
    }).then((res) => setPropertyTypes(res.response));
  }, [i18n]);

  React.useEffect(() => {
    if (openNewADsDialog) {
      setLoadingProperties(true);
      getMyProperties({
        userId: token,
        page: page,
        status: "1",
        pageSize: pageSize,
        isAssigned: "All",
        language: i18n.language === 'ar' ? '0' : '1',
        propertyType: propertyType === '-1' ? "" : propertyType
      })
        .then((property) => {
          if (property.resCode === 0) {
            setPropertiesForAdd(property.response.propertyArray);
            setSelectedProperties([]);
            setLoadingProperties(false);
            setTotalCountForAdd(property.response.totalCount);
          } else {
            toast.error(property.resStr);
            setLoadingProperties(false);
          }
        });
    }
  }, // eslint-disable-next-line
    [token, page, i18n, openNewADsDialog, propertyType]);

    const handlePageChange = (event, value) => {
      setPage(value);
    };

    const handleSave = () => {
      if (selectedProperties?.length > 0) {
            setLoadingButton(true);
            saveVip({
              "language": i18n.language === "ar" ? '0' : '1',
              "userId": token,
              "value": "1",
              "properties": selectedProperties
            }).then((res) => {
              if (res?.resCode === 0) {
                setOpenNewADsDialog(false);
                setLoadingProperties(true);
                onChange?.();
                setSelectedProperties([]);
                setPropertyType("-1");
                toast.success(t("DoneSuccessfully"));
              }
            }).finally(() => setLoadingButton(false));
          
        
      } else {
        toast.error(t("PleaseSelectRealEstateADAtLeast"));
      }
  };
  
  
  function onChange() {
    setSettings({ ...Settings, pageSize: "100" });
    setSearchParams({
      page: Settings.page,
      status: Settings.status,
      pageSize: "100",
    },
      { replace: true });
  }


  useEffect(() => {
    setLoadingData(true);
    getVipOnly({
      ...Settings,
      language: i18n.language === 'ar' ? '0' : '1',
    })
      .then((property) => {
        if (property.resCode === 0) {
          setProperties(property.response.propertyArray);
          setTotalCount(property.response.totalCount);
          setLoadingData(false);
     
        } else {
          toast.error(property.resStr);
          setLoadingData(false);
        }
      });
    
  },// eslint-disable-next-line
    [Settings, i18n.language]);


  const handleUnFeatured = (selectedProperty) => {
    openDeleteModal(
      t("ConfirmDediscrimination"),
      t("AreYouSureYouWantToDeDiscriminateThisAD"),
      () => {
        setLoadingPublishButton(true);
        
        saveVip({
          "language": i18n.language === "ar" ? '0' : '1',
          "userId": token,
          "value": "0",
          "properties": [selectedProperty?.propertyId]
        })
          .then((property) => {
            if (property.resCode === 0) {
              toast.success(t("ADSuccessfullyDediscriminated"));
              onChange();
            } else {
              toast.error(property.resStr);
            }
          })
          .finally(() => {
            setLoadingPublishButton(false);
          });
        
      },
      t("Confirm"));
  };


  const renderPropertiesList = () => {
    return (
      <div className="properties-list col-12 px-0 pt-0 pb-5">
        <div className='row mx-0'>
          {loadingData ? (
            <LoadingData />
          ) : Properties && Properties.length > 0 ? (
            <Grid container gap={2} sx={{width:'100%',margin:0}} alignItems={"center"} justifyContent={"center"}>
              {Properties.map((item, i) => (
                <Grid item xs={12} md={6} lg={4} xl={3} key={i} className="custom-grid-item">
                  <PropertyCardForAdmin
                    item={item}
                    vip
                    isUserVerified={'3'}
                    loadingPublishButton={loadingPublishButton}
                    onChange={onChange}
                    selectedProperty={selectedProperty}
                    setSelectedProperty={setSelectedProperty}
                    handlePublishAndUnPublish={handleUnFeatured}
                    userId={props.userId}
                    hideActions
                  />
                </Grid>
              ))}
          
            </Grid>) : (
            <NoData msg={t("ThereAreNoADs")} />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="px-1">
        <SubHeader OKElement={
          <Fab
          size="large"
          color="primary"
          className="ok-fab"
          onClick={()=>setOpenNewADsDialog(true)}
        >
          <Plus sx={{ fontSize: 30 }} />
          <span>{t("NewAD")}</span>
        </Fab>
        } />
   
     
      <div className='row properties-list-wrapper m-0 radius-16'>
        <div className='col-12 p-0 heading' style={{top:'40px'}}>
          <div className='row '>
            <div className='col-12 col-lg-6 text-center text-lg-start mb-0 d-flex flex-wrap align-items-center' style={{ gap: '15px' }}>
              <h4 className="mb-0" style={{direction:'rtl !important'}}>
                {t("VIPADs")} ({!loadingData ? TotalCount : <>&nbsp;&nbsp;&nbsp;</>})
              </h4>
            </div>
          </div>
        </div>
        {renderPropertiesList()}
      </div>
  
      <Dialog
      fullWidth={true}
      maxWidth={"xl"}
      open={openNewADsDialog}
      className="custom-dialog model"
      onClose={() => {
        setOpenNewADsDialog(!openNewADsDialog);
        setLoadingProperties(true);
        setSelectedProperties([]);
        setPropertyType("-1");
      }}>
      <DialogTitle>
        <div className="dialog-head flex-column flex-lg-row">
          <div>
            <h2>{ t("ChooseADs")}</h2>
              <p>{t("YouCanChooseOneADOrMore")}</p>
          </div>
          <div className="dialog-actions flex-row">
            <LoadingButton label={t("Save")} classes="px-3 primary-btn" loading={loadingButton} handleClick={handleSave} />
           
            <button className="px-3 outlined-btn" onClick={() => {
              setOpenNewADsDialog(!openNewADsDialog);
              setLoadingProperties(true);
              setSelectedProperties([]);
              setPropertyType("-1");
            }}>
              {t("Cancel")}
            </button>
          </div>
        </div>
          
      </DialogTitle>
      <DialogContent>
          <div className="row mx-0 mt-2 align-items-center mb-4 mb-lg-3 " style={{ gap: '10px' }}>
            <div className="col-12 col-md-5 col-lg-3 p-0" style={{ maxWidth: '220px' }}>
              <FormControl
                className="custom-select">
                <InputLabel htmlFor='propertyType' required>{t("PropertyType")}</InputLabel>
                <Select
                  value={propertyType}
                  required
                  sx={{ width: "100%" }}
                  onChange={(event) => setPropertyType(event.target.value)}
                  input={<OutlinedInput
                    notched
                    label={t("PropertyType")}
                    id="type"
                  />}
                  label={t("PropertyType")}
                  inputProps={{
                    name: "propertyType",
                    id: "propertyType",
                  }}>
                  <MenuItem value={'-1'} key={'-1'}>{t("All")}</MenuItem>
                  {propertyTypes.map((item) => <MenuItem value={item.key} key={item.key}>{item.name}</MenuItem>)}
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-7 col-lg-6 p-0">
              {selectedProperties?.length  > 0 && <span>
                {t("RealEstateADsSelected", { num: selectedProperties?.length })}
              </span>}
            </div>
          </div>
        
        <div className='custom-card' style={{ border: 'none' }}>
            <Grid container spacing={3}>
              {loadingProperties ? <Grid xs={12} item className="mb-3">
                <LoadingData />
              </Grid> : propertiesForAdd?.length>0 ? propertiesForAdd.map((item, i) => (
                <Grid xs={12} md={6} lg={4} xl={2} item className="mb-3" key={i}>
                  <PropertyCardForAdmin
                    item={item}
                    hideActions
                    disableLink
                    multipleSelect
                    selectedProperties={selectedProperties}
                    setSelectedProperties={setSelectedProperties}
                  />
                </Grid>
              )) : <NoData msg={t("ThereAreNoADsPublishedByYou")} />}
            </Grid>
         
        </div>
        {totalCountForAdd > pageSize && (
          <Pagination
            sx={{ '& .MuiPagination-ul': { flexDirection: 'row', marginTop: '15px' } }}
            count={parseInt(Math.ceil(totalCountForAdd / pageSize))}
            page={page}
            onChange={handlePageChange}
            color="primary" />
        )}
      </DialogContent>
    </Dialog>
    </div>
  );
}

export default MyListings;
