import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  getPropertyDescription,
  uploadPropertyImage,
  savePropertyFloorPlans,
  addPropertyStep2,
  publishMyListing,
  uploadVideo,
  // platformCompliance,
} from "../../../../api/propertyApi";
import {
  getUserPlan,
  translateText,
  uploadFileBase64,
  uploadWlFileBase64,
} from "../../../../api/userApi";
import Switch from "@mui/material/Switch";
import { confirmAlert } from "react-confirm-alert"; // Import
// import { logEvent } from "firebase/analytics";
// import { analytics } from "../../../../Config/Firebase";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Resizer from "react-image-file-resizer";
import Stack from "@mui/material/Stack";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Scrollbar } from "swiper";
import { Trash } from "../../../../constants/icons";
import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Slider,
  OutlinedInput,
  LinearProgress,
  Box,
  Typography,
  DialogContent,
  Dialog,
} from "@mui/material";
import { Check } from "@mui/icons-material";
import { ThemeProvider } from "@mui/material/styles";
import SubHeader from "../../../../components/global-components/sub-header";
import LoadingButton from "../../../../components/global-components/loading-btn";
import { useAuth } from "../../../../helpers/context";
import {
  getDemoSnakbar,
  getLocalizedText,
  preventString,
  resolvePersianAndArabicNumbers,
} from "../../../../helpers";
import DragAndDropFileUploader from "../../../../components/global-components/drag-drop-file-uploader";
import {
  DEMO_VENDORS,
  HIDDEN_ADITTIONAL_INFO_FOR_ADD,
  HIDDEN_FEATURES,
  HIDDEN_ROOMS,
  IGNORED_VALIDATION_KEYS,
  MAX_VIDEO_SIZE,
} from "../../../../constants";
// import { FFmpeg } from '@ffmpeg/ffmpeg'
import VideoSnapshot from "video-snapshot";
import { useTranslation } from "react-i18next";
import SetPropertyLocationDialog from "../../properties/components/set-property-location-dialog";

const isAdmin = window.location.href.includes("admin") ? true : false;
const publicUrl = process.env.REACT_APP_URL + "/";

const EditProperty = (props) => {
  const history = useNavigate();
  const isNew = window.location.href.indexOf("add") > -1;
  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
  const [openVideoDialog, setOpenVideoDialog] = useState({
    visible: false,
    url: "",
  });
  const { token, UserId, vendor, autoTranslate } = useAuth();
  const featureRef = useRef(null);
  const sScrollToFeatureRef = () =>
    featureRef?.current?.scrollIntoView({
      behavior: "smooth",
    });
  const additionalInfoRef = useRef(null);
  const sScrollToAdditionalInfoRef = () =>
    additionalInfoRef?.current?.scrollIntoView({
      behavior: "smooth",
    });
  const [Property, setProperty] = useState({});
  const [errors, setErrors] = useState({ feature: [], additionalInfo: [] });
  const PropertyParams = props.AllParams;
  const [Images, setImages] = useState("");
  const [Videos, setVideos] = useState([]);
  const [Plans, setPlans] = useState("");
  const [uploadedPicMsg, setuploadedPicMsg] = useState("");
  const [uploadingVideo, setuploadingVideo] = useState(false);

  const [uploadedPlanMsg, setuploadedPlanMsg] = useState("");
  const [Rooms, setRooms] = useState({});
  const [Features, setFeatures] = useState([]);
  const [AdditionalInfos, setAdditionalInfo] = useState([]);
  const [Amenities, setAmenities] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [Description, setDescription] = useState("");
  const [oldDescription, setOldDescription] = useState("");
  const [Price, setPrice] = useState("");
  const [PriceRent, setPriceRent] = useState([]);
  const [PricePerMeter, setPricePerMeter] = useState("");
  const [NewProperty, setNewProperty] = useState({
    propertyId: props.propertyId,
  });
  const { t, i18n } = useTranslation();
  const [uploadedVideos, setUploadedVideos] = useState(1);
  const [totalVideos, setTotalVideos] = useState(0);
  const [uploadedImages, setUploadedImages] = useState(1);
  const [totalImages, setTotalImages] = useState(0);
  const [uploadedPlans, setUploadedPlans] = useState(1);
  const [totalPlans, setTotalPlans] = useState(0);
  // const [userLogo, setUserLogo] = useState('');
  const [propertiesLeft, setPropertiesLeft] = useState(0);
  const [photosNew, setPhotosNew] = useState([]);
  const [floorPlans, setFloorPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState();
  const [openMapDialog, setOpenMapDialog] = useState({
    visible: false,
    property: undefined,
  });

  // const ffmpeg = new FFmpeg({ log: true });

  const uploadedVideosProgress = useMemo(() => {
    if (
      totalVideos === 0 ||
      (totalVideos > 0 && uploadedVideos - 1 === totalVideos)
    )
      return 0;
    return (uploadedVideos * 100) / totalVideos > 100
      ? 100
      : (uploadedVideos * 100) / totalVideos;
  }, [uploadedVideos, totalVideos]);

  const uploadedImagesProgress = useMemo(() => {
    if (
      totalImages === 0 ||
      (totalImages > 0 && uploadedImages - 1 === totalImages)
    )
      return 0;
    return (uploadedImages * 100) / totalImages > 100
      ? 100
      : (uploadedImages * 100) / totalImages;
  }, [uploadedImages, totalImages]);

  const uploadedPlansProgress = useMemo(() => {
    if (
      totalPlans === 0 ||
      (totalPlans > 0 && uploadedPlans - 1 === totalPlans)
    )
      return 0;
    return (uploadedPlans * 100) / totalPlans > 100
      ? 100
      : (uploadedPlans * 100) / totalPlans;
  }, [uploadedPlans, totalPlans]);

  useEffect(() => {
    if (token) {
      getUserPlan({
        userId: token,
        language: i18n.language === "ar" ? "0" : "1",
      }).then((result) => {
        if (result.resCode === 0) {
          setCurrentPlan(result.response);
          setPropertiesLeft(result.response.propertiesLeft);
        }
      });
    }
  }, [token, i18n]);

  useEffect(
    () => {
      if (totalImages > 0 && uploadedImages - 1 === totalImages) {
        setTotalImages(0);
        setuploadedPicMsg("");
        setUploadedImages(1);
        if (vendor !== "amakkn") {
          if (!DEMO_VENDORS.includes(vendor)) {
            let Request = getRequestForSave(false, Images, Plans);
            addPropertyStep2(Request).then((res) => {
              if (res.resCode === "0") {
                getPropertyData();
                setTotalPlans(0);
                setuploadedPlanMsg("");
              } else {
                setuploadedPicMsg("");
                setuploadedPlanMsg("");
                toast.error(res.resStr);
              }
            });
          }
        } else {
          getPropertyData();
        }
      }
    }, // eslint-disable-next-line
    [uploadedImages]
  );

  useEffect(
    () => {
      if (totalPlans > 0 && uploadedPlans - 1 === totalPlans) {
        if (!DEMO_VENDORS.includes(vendor)) {
          let Request = getRequestForSave(false, Images, Plans);
          addPropertyStep2(Request).then((res) => {
            if (res.resCode === "0") {
              getPropertyData();
              setTotalPlans(0);
              setuploadedPlanMsg("");
            } else {
              setuploadedPicMsg("");
              setuploadedPlanMsg("");
              toast.error(res.resStr);
            }
          });
        } else {
          setTotalPlans(0);
          setuploadedPlanMsg("");
          setUploadedPlans(1);
        }
      }
    }, // eslint-disable-next-line
    [uploadedPlans]
  );

  useEffect(
    () => {
      if (totalVideos > 0 && uploadedVideos - 1 === totalVideos) {
        if (!DEMO_VENDORS.includes(vendor)) {
          getPropertyData();
        }
        setTotalVideos(0);
        setUploadedVideos(1);
        setuploadingVideo(false);
      }
    }, // eslint-disable-next-line
    [uploadedVideos]
  );

  const getPropertyData = () => {
    setUploadedImages(1);
    setUploadedPlans(1);
    setUploadedVideos(1);
    getPropertyDescription({
      propertyId: NewProperty.propertyId,
      text: NewProperty.response,
      language: i18n.language === "ar" ? "0" : "1",
      userId: UserId,
    })
      .then(async (property) => {
        if (property.resCode === 0) {
          setProperty(property.response);
          // setUserLogo(property.response.hostInfo?.avatar);
          if (property.response.floorPlans) {
            setPlans(property.response.floorPlans);
            setFloorPlans(property.response.floorPlans?.split(","));
          }
          if (property.response.photosNew) {
            setImages(property.response.photosNew);
            setPhotosNew(property.response.photosNew?.split(","));
          } else {
            setImages("");
          }
          if (
            property.response.videoUrl &&
            property.response.videoUrl?.length > 0
          ) {
            let temp = [];
            for (let item of property.response.videoUrl) {
              let response = await fetch(item.video_url);
              let data = await response.blob();
              let file = new File([data], "test.mp4", { type: "video/mp4" });
              const snapshoter = new VideoSnapshot(file);
              const previewSrc = await snapshoter.takeSnapshot(10);
              temp.push({ ...item, thumbnail: previewSrc });
            }
            setVideos(temp);
          } else {
            setVideos([]);
          }

          if (property.response.rooms.length !== 0) {
            setRooms(property.response.rooms);
          } else {
            let roomArr = [];
            props.AllParams.room.forEach((element) => {
              roomArr.push({ key: element.key, value: element.lowerLimit });
            });
            setRooms(roomArr);
          }

          if (property.response.features.length !== 0) {
            let featuresArr = [];
            property.response.features.forEach((element) => {
              if (element.key !== "100") featuresArr.push(element);
            });
            setFeatures(featuresArr);
          } else {
            let featuresArr = [];
            props.AllParams.feature.forEach((element) => {
              if (element.key !== "100")
                featuresArr.push({ key: element.key, value: "" });
            });
            setFeatures(featuresArr);
          }
          if (property.response.additionalInfo.length !== 0) {
            if (
              property.response.additionalInfo.length ===
              props.AllParams.additionalInfo.length
            ) {
              setAdditionalInfo(property.response.additionalInfo);
            } else {
              let AdditionalInfoArr = property.response.additionalInfo;
              props.AllParams.additionalInfo.forEach((element) => {
                let ValueBool = "";
                if (element?.typeOfField === "3") ValueBool = "0";
                if (
                  AdditionalInfoArr.find((elem) => elem.key === element.key) ===
                  undefined
                ) {
                  AdditionalInfoArr.push({
                    key: element.key,
                    valueBoolean: ValueBool,
                    valueText: "",
                  });
                }
              });

              setAdditionalInfo(AdditionalInfoArr);
            }
          } else {
            let additionalInfoArr = [];
            props.AllParams.additionalInfo.forEach((element) => {
              let ValueBool = "";
              if (element?.typeOfField === "3") ValueBool = "0";
              additionalInfoArr.push({
                key: element.key,
                valueBoolean: ValueBool,
                valueText: "",
              });
            });
            setAdditionalInfo(additionalInfoArr);
          }

          if (property.response.amenities.length !== 0) {
            let AmenitiesArr = [];
            property.response.amenities.forEach((element) => {
              AmenitiesArr.push(element.key);
            });
            setAmenities(AmenitiesArr);
          }

          if (property.response.description) {
            setDescription(property.response.description);
            setOldDescription(property.response.description);
          }

          if (property.response.defaultPriceFomratted !== "0")
            setPrice(property.response.defaultPriceFomratted);

          if (property.response.listedFor === "1") {
            let priceRent = [];

            props.AllParams?.price?.forEach((element) => {
              let a = element;
              property.response.priceRent.forEach((priceRentElement) => {
                if (priceRentElement.key === element.key)
                  a = { ...element, value: priceRentElement.value };
              });
              priceRent.push(a);
            });
            setPriceRent(priceRent);
          }

          if (property.response.features.length !== 0) {
            property.response.features.forEach((element) => {
              if (element.key === "1" || element.key === "13") {
                let price = property.response.defaultPrice.replace(/[,]+/g, "");
                let pricePerMeter =
                  parseFloat(price) / parseFloat(element.value);
                setPricePerMeter(pricePerMeter + "");
              }
            });
          }
        } else {
          toast.error(property.resStr);
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  useEffect(
    () => {
      getPropertyData();
    }, // eslint-disable-next-line
    [
      NewProperty,
      props.AllParams.feature,
      props.AllParams.additionalInfo,
      props.AllParams.price,
      props.AllParams.room,
      UserId,
    ]
  );

  const onDrop = (picture) => {
    let files = picture.length > 30 ? picture.slice(0, 30) : picture;
    DropImage(files, "photo");
  };

  const onDropVideos = async (videosFiles) => {
    let files = videosFiles.length > 3 ? videosFiles.slice(0, 3) : videosFiles;
    files.map((file, index) => {
      if (file.size / 1024 / 1024 > MAX_VIDEO_SIZE) {
        toast.error(t("YouCannotUploadVideoLarger", { size: MAX_VIDEO_SIZE }));
        files = files.filter((f, i) => i !== index);
      }
      return null;
    });
    let i = 1;
    let oldIndexes = Videos.map((v) => v.index);
    const forLoop = async (_) => {
      for (let v of files) {
        setuploadingVideo(true);
        // ffmpeg.on("log", ({ type, message }) => {
        //   console.log({
        //     type,
        //     message
        //   })
        // })
        // compress video
        // await ffmpeg.load();
        // const data = await fetchFile(v);
        // await ffmpeg.writeFile('input.mp4', data)
        // // await ffmpeg.exec(["-i", 'input.mp4', "video.mp4"]);
        // await ffmpeg.exec(['-i', 'input.mp4', '-c:v', 'libx264', '-crf', '28', '-preset', 'fast', 'output.mp4']);
        // const compressedData = await ffmpeg.readFile('output.mp4')//ffmpeg.FS('readFile', 'output.mp4');

        // console.log(compressedData);
        // const blob = new Blob([compressedData.buffer], { type: 'video/mp4' });
        const snapshoter = new VideoSnapshot(v);
        const previewSrc = await snapshoter.takeSnapshot(10);

        uploadVideo({
          propertyId: props.propertyId,
          language: i18n.language === "ar" ? "0" : "1",
          userId: token,
          file: v,
          index: !oldIndexes.includes(i)
            ? i
            : !oldIndexes.includes(i + 1)
            ? i + 1
            : i + 2,
        })
          .then((resp) => {
            if (resp.resCode === 0) {
              // videos.push(resp.response.video);
              setUploadedVideos((val) => val + 1);
              setVideos((old) => [
                ...old,
                {
                  index: !old.map((v) => v.index)?.includes(1)
                    ? 1
                    : !old.map((v) => v.index)?.includes(2)
                    ? 2
                    : 3,
                  video_url: resp.response.video,
                  thumbnail: previewSrc,
                },
              ]);
            } else {
              setuploadingVideo(false);
              toast.error(resp.resStr);
            }
          })
          .catch((e) => {
            setuploadingVideo(false);
            setTotalVideos(0);
            getPropertyData();
          });
        i++;
      }
    };

    if (Videos.length + files.length > 3) {
      toast.error(t("YouCannotUploadMoreThan3Videos"));
      return;
    }
    setTotalVideos(files.length);
    await forLoop();
  };

  // function processWatermark() {
  //   return function (target, logo) {
  //     var context = target.getContext('2d');
  //     context.save();
  //     context.globalAlpha = 1;
  //     const posY = target.height;
  //     context.drawImage(logo, 15, posY - 115, logo.width / 2.8, logo.height / 2.8);
  //     context.restore();
  //     return target;
  //   }
  // }

  async function DropImage(picture, source) {
    let plans = Plans !== "" ? Plans?.split(",") : [],
      imgs = Images !== "" ? Images?.split(",") : [];
    const forLoop = async (_) => {
      for (let imag of picture) {
        let imgHeights = 100;
        let imgWidth = 100;
        let maxWidth = 1920;
        let maxHeight = 1080;

        let reader = new FileReader();
        reader.readAsDataURL(imag);
        if (source === "photo") setuploadedPicMsg(t("ImagesAreUploading"));
        else setuploadedPlanMsg(t("PlansAreUploading"));
        if (imag.type !== "application/pdf") {
          reader.onloadend = async () => {
            // if (userLogo && currentPlan?.canAddLogo==="1") {
            //   watermark([reader.result, userLogo.indexOf('?') > -1 ?
            //     userLogo.substring(0, userLogo.indexOf('?')) :
            //     userLogo],
            //     {
            //       init(img) {
            //         img.crossOrigin = 'anonymous'
            //       }
            //     })
            //     .image(processWatermark())
            //     .then((img) => {
            //       img.onload = async () => {
            //         imgHeights = img.height;
            //         imgWidth = img.width;

            //         let imgRatio = imgWidth / imgHeights;
            //         let maxRatio = maxWidth / maxHeight;

            //         if (imgHeights > maxHeight || imgWidth > maxWidth) {
            //           if (imgRatio < maxRatio) {
            //             imgRatio = maxHeight / imgHeights;
            //             imgWidth = imgRatio * imgWidth;
            //             imgHeights = maxHeight;
            //           } else if (imgRatio > maxRatio) {
            //             imgRatio = maxWidth / imgWidth;
            //             imgHeights = imgRatio * imgHeights;
            //             imgWidth = maxWidth;
            //           } else {
            //             imgHeights = maxHeight;
            //             imgWidth = maxWidth;
            //           }
            //         }

            //         var mimeString = img.src
            //           .split(",")[0]
            //           .split(":")[1]
            //           .split(";")[0];

            //         var binary = atob(img.src.split(",")[1]);
            //         var array = [];
            //         for (var i = 0; i < binary.length; i++) {
            //           array.push(binary.charCodeAt(i));
            //         }
            //         var blobImage = new Blob([new Uint8Array(array)], {
            //           type: mimeString,
            //         });
            //         var uploadFile = new File([blobImage], imag.name);

            //         await resizeFile(
            //           uploadFile,
            //           imgHeights,
            //           imgWidth,
            //           source,
            //           picture[picture.length - 1].name,
            //           plans,
            //           imgs
            //         );
            //       };
            //     });
            // } else {
            let img = document.createElement("img");
            img.src = reader.result;
            img.onload = async () => {
              // imgHeights = img.height;
              // imgWidth = img.width;
              // let imgRatio = imgWidth / imgHeights;
              // let maxRatio = maxWidth / maxHeight;

              // if (imgHeights > maxHeight || imgWidth > maxWidth) {
              //   if (imgRatio < maxRatio) {
              //     imgRatio = maxHeight / imgHeights;
              //     imgWidth = imgRatio * imgWidth;
              //     imgHeights = maxHeight;
              //   } else if (imgRatio > maxRatio) {
              //     imgRatio = maxWidth / imgWidth;
              //     imgHeights = imgRatio * imgHeights;
              //     imgWidth = maxWidth;
              //   } else {
              //     imgHeights = maxHeight;
              //     imgWidth = maxWidth;
              //   }
              // }
              let ratio = Math.min(
                maxWidth / img.width,
                maxHeight / img.height
              );

              imgWidth = img.width * ratio;
              imgHeights = img.height * ratio;

              var mimeString = img.src
                .split(",")[0]
                .split(":")[1]
                .split(";")[0];

              var binary = atob(img.src.split(",")[1]);
              var array = [];
              for (var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
              }
              var blobImage = new Blob([new Uint8Array(array)], {
                type: mimeString,
              });
              var uploadFile = new File([blobImage], imag.name);

              await resizeFile(
                uploadFile,
                imgHeights,
                imgWidth,
                source,
                picture[picture.length - 1].name,
                plans,
                imgs
              );
            };
            // }
          };
        } else {
          reader.onload = async () => {
            let fileInfo = {
              pathWithFileName:
                "Property/" +
                props.propertyId +
                `/${source === "photo" ? "Photos" : "Plans"}/` +
                Date.now() +
                imag?.name?.substring(imag?.name?.lastIndexOf(".")),
              base64Data: reader.result,
              typeOfFile: imag.type !== "application/pdf" ? imag.type : "pdf",
              userId: token,
              language: i18n.language === "ar" ? "0" : "1",
            };

            if (DEMO_VENDORS.includes(vendor)) {
              if (source === "plan") {
                plans.push(fileInfo.base64Data);
                setUploadedPlans((val) => val + 1);
              } else {
                imgs.push(fileInfo.base64Data);
                setUploadedImages((val) => val + 1);
              }
              if (source === "plan")
                setFloorPlans((old) => [...old, fileInfo.base64Data]);
              else setPhotosNew((old) => [...old, fileInfo.base64Data]);
            } else {
              if (vendor === "amakkn") {
                uploadFileBase64(fileInfo)
                  .then((resp) => {
                    if (resp.resCode === 0) {
                      if (source === "plan") {
                        plans.push(resp.response.url);
                        setUploadedPlans((val) => val + 1);
                      } else {
                        imgs.push(resp.response.url);
                        setUploadedImages((val) => val + 1);
                      }
                      // let Request = getRequestForSave(false, imgs.join(','), plans.join(','));
                      if (source === "plan") setPlans(plans.join(","));
                      else setImages(imgs.join(","));
                    } else {
                      setuploadedPicMsg("");
                      setuploadedPlanMsg("");
                      toast.error(resp.resStr);
                    }
                  })
                  .catch((e) => {
                    setuploadedPicMsg("");
                    setuploadedPlanMsg("");
                  });
              } else {
                uploadWlFileBase64(fileInfo)
                  .then((resp) => {
                    if (resp.resCode === 0) {
                      if (source === "plan") {
                        plans.push(resp.response.url);
                        setUploadedPlans((val) => val + 1);
                      } else {
                        imgs.push(resp.response.url);
                        setUploadedImages((val) => val + 1);
                      }
                      // let Request = getRequestForSave(false, imgs.join(','), plans.join(','));
                      if (source === "plan") setPlans(plans.join(","));
                      else setImages(imgs.join(","));
                    } else {
                      setuploadedPicMsg("");
                      setuploadedPlanMsg("");
                      toast.error(resp.resStr);
                    }
                  })
                  .catch((e) => {
                    setuploadedPicMsg("");
                    setuploadedPlanMsg("");
                  });
              }
            }
          };
        }
      }
    };

    if (source === "photo") {
      if (imgs.length + picture.length > 30) {
        toast.error(t("YouCannotUploadMoreThan30Images"));
        return;
      }
      setTotalImages(picture.length);
      await forLoop();
    } else {
      if (plans.length + picture.length > 5) {
        toast.error(t("YouCannotUploadMoreThan5Files"));
        return;
      }
      setTotalPlans(picture.length);
      await forLoop();
    }
  }

  const resizeFile = (
    file,
    imgHeights,
    imgWidth,
    source,
    fileName,
    plans,
    imgs
  ) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        imgHeights,
        imgWidth,
        "JPEG",
        100,
        0,
        async (uri) => {
          await uploadPropertyImage({
            propertyId: props.propertyId,
            pathWithFileName:
              "Property/" +
              props.propertyId +
              `/${source === "photo" ? "Photos" : "Plans"}/` +
              Date.now() +
              file?.name?.substring(file?.name?.lastIndexOf(".")),
            userId: props.userId,
            base64Data: uri,
            type: source,
          })
            .then((property) => {
              if (property.resCode === 0) {
                if (source === "photo") {
                  setUploadedImages((val) => val + 1);
                  imgs.push(property.response.url);
                  setImages(imgs.join(","));
                } else {
                  plans.push(property.response.url);
                  setPlans(plans.join(","));
                  setUploadedPlans((val) => val + 1);
                }
                // let lastUploadedName = property.response.name;
                // lastUploadedName = lastUploadedName.replace(
                //   "Property/" + props.propertyId + "/Photos/",
                //   ""
                // );

                // if (lastUploadedName === fileName) {
                //   setNewProperty({
                //     propertyId: props.propertyId,
                //   });
                //   setuploadedPlanMsg("");
                //   setuploadedPicMsg("");
                // }
              } else {
                setuploadedPicMsg("");
                setuploadedPlanMsg("");
                toast.error(property.resStr);
              }
            })
            .catch((error) => {
              setuploadedPlanMsg("");
              setuploadedPicMsg("");
              setTotalImages(0);
              setTotalPlans(0);
              getPropertyData();
            });
        },
        "base64"
      );
    });

  function handleDeleteImage(target) {
    if (DEMO_VENDORS.includes(vendor)) {
      setPhotosNew((old) => [
        ...old?.filter((i) => i !== target?.currentTarget?.title),
      ]);
    } else {
      let photos = "";
      let deleted = target.currentTarget.title;
      const photosArr = Images.split(",");

      let photosArrWihtoutTitle = [];
      photosArr.forEach((element) => {
        photosArrWihtoutTitle.push(element.substring(0, element.indexOf("#")));
      });
      var imageWihtoutTitle = deleted.substring(0, deleted.indexOf("#"));
      const index = photosArrWihtoutTitle.indexOf(imageWihtoutTitle);
      photosArr.splice(index, 1);

      photosArr.forEach((element) => {
        photos = photos + element + ",";
      });

      if (photos.length > 1) photos = photos.slice(0, -1);
      if (photos.length < 2) photos = "-1";

      let Request = getRequestForSave(false, photos, Plans);
      addPropertyStep2(Request).then((property) => {
        if (property.resCode === "0") {
          setNewProperty({
            title: property.response,
            propertyId: props.propertyId,
            photos: property.response,
          });
        } else {
          toast.error(property.resStr);
        }
      });
    }
  }

  function handleDeleteVideo(target) {
    if (DEMO_VENDORS.includes(vendor)) {
      setVideos((old) => [...old?.filter((i) => i.index !== target?.index)]);
    } else {
      uploadVideo({
        propertyId: props.propertyId,
        language: i18n.language === "ar" ? "0" : "1",
        userId: token,
        index: target.index,
      }).then((resp) => {
        if (resp.resCode === 0) {
          setVideos((old) => [
            ...old?.filter((i) => i.index !== target?.index),
          ]);
        } else {
          toast.error(resp.resStr);
        }
      });
    }
  }

  function handleSetDefaultImage(target) {
    if (DEMO_VENDORS.includes(vendor)) {
      let temp = photosNew?.filter((i) => i !== target?.currentTarget?.title);
      temp?.unshift(target?.currentTarget?.title);
      setPhotosNew(temp);
    } else {
      let photos = "";
      let image = target.currentTarget.title;
      const photosArr = Images.split(",");
      let photosArrWihtoutTitle = [];

      photosArr.forEach((element) => {
        photosArrWihtoutTitle.push(element.substring(0, element.indexOf("#")));
      });

      var imageWihtoutTitle = image.substring(0, image.indexOf("#"));
      const index = photosArrWihtoutTitle.indexOf(imageWihtoutTitle);
      var imageWihtTitle = photosArr[index];
      photosArr.splice(index, 1);
      photosArr.unshift(imageWihtTitle);
      photosArr.forEach((element) => {
        photos = photos + element + ",";
      });
      if (photos.length > 1) photos = photos.slice(0, -1);
      if (photos.length < 2) photos = "-1";

      let Request = getRequestForSave(false, photos, Plans);
      addPropertyStep2(Request).then((property) => {
        if (property.resCode === "0") {
          setNewProperty({
            title: property.response,
            propertyId: props.propertyId,
            photos: property.response,
          });
        } else {
          toast.error(property.resStr);
        }
      });
    }
  }

  function getTitle(image) {
    var title;
    if (Images) {
      let photosArr = Images.split(",");
      let photosArrWihtoutTitle = [];
      photosArr.forEach((element) => {
        photosArrWihtoutTitle.push(element.substring(0, element.indexOf("#")));
      });
      var imageWihtoutTitle = image.substring(0, image.indexOf("#"));
      var index = photosArrWihtoutTitle.indexOf(imageWihtoutTitle);
      var str = photosArr[index];
      if (str) title = str.substring(str.indexOf("#") + 1);
    }
    return title;
  }

  function onTitleChange(target) {
    let value = resolvePersianAndArabicNumbers(target.target.value);
    let img = target.target.title;

    let photosArr = Images.split(",");
    let photosArrWihtoutTitle = [];
    photosArr.forEach((element) => {
      photosArrWihtoutTitle.push(element.substring(0, element.indexOf("#")));
    });
    var imageWihtoutTitle = img.substring(0, img.indexOf("#"));
    var index = photosArrWihtoutTitle.indexOf(imageWihtoutTitle);
    var str = photosArr[index];
    var res = str.substring(0, str.indexOf("#"));
    res = res + "#" + value;

    photosArr[index] = res;

    let photos = "";
    photosArr.forEach((element) => {
      photos = photos + element + ",";
    });

    if (photos.length > 1) photos = photos.slice(0, -1);
    setImages(photos);
  }

  // Room
  function handleRoomChange(e, Room) {
    let value = +preventString(e.target.value);
    let max = +e.target.max;
    let min = +e.target.min;

    if (value > max) {
      value = max;
    }
    if (value < min) {
      value = min;
    }
    let key = Room.key;
    setRooms(
      Rooms.map((el) => (el.key === key ? { ...el, value: value } : el))
    );
  }

  function getRoomValue(roomKey) {
    let roomValue;
    if (Rooms.length > 0) {
      let roomItem = Rooms.filter((e) => e.key === roomKey);
      if (roomItem.length > 0) roomValue = roomItem[0].value;
      else roomValue = "0";
    }
    return roomValue;
  }

  //AdditionalInfo

  function handleAdditionalInfoTextChange(e, AdditionalInfo) {
    let maxLength = AdditionalInfo.key === "4" ? 10 : 500;
    let value = resolvePersianAndArabicNumbers(
      e.currentTarget.value?.length >= maxLength
        ? e.currentTarget.value?.substring(0, maxLength)
        : e.currentTarget.value
    );
    let key = AdditionalInfo.key;

    if (AdditionalInfos.length > 0) {
      let AdditionalInfoItem = AdditionalInfos.filter((e) => e.key === key);
      if (AdditionalInfoItem.length > 0) {
        setAdditionalInfo(
          AdditionalInfos.map((el) =>
            el.key === key ? { ...el, valueText: value } : el
          )
        );
      } else {
        setAdditionalInfo([
          ...AdditionalInfos,
          { key: key, valueText: value, valueBoolean: "1" },
        ]);
      }
    } else {
      setAdditionalInfo([{ key: key, valueText: value, valueBoolean: "1" }]);
    }

    if (errors.additionalInfo.length > 0) {
      setErrors({
        ...errors,
        additionalInfo: errors.additionalInfo.filter(
          (e) => e.key !== AdditionalInfo.key
        ),
      });
    }
  }

  function handleAdditionalInfoBooleanChange(boolValue, AdditionalInfo) {
    const _errors = errors.additionalInfo.filter(function (el) {
      return el.key !== AdditionalInfo.key;
    });

    setErrors({ ...errors, additionalInfo: _errors });

    let key = AdditionalInfo.key;
    let value = boolValue === true ? "1" : "0";

    if (AdditionalInfos.length > 0) {
      let AdditionalInfoItem = AdditionalInfos.filter((e) => e.key === key);
      if (AdditionalInfoItem.length > 0) {
        setAdditionalInfo(
          AdditionalInfos.map((el) =>
            el.key === key ? { ...el, valueBoolean: value } : el
          )
        );
      } else {
        setAdditionalInfo([
          ...AdditionalInfos,
          {
            key: key,
            valueText: "",
            valueBoolean: value,
          },
        ]);
      }
    } else {
      setAdditionalInfo([
        {
          key: key,
          valueText: "",
          valueBoolean: value,
        },
      ]);
    }
  }

  function getAdditionalInfoTextValue(additionalInfoKey) {
    let AdditionalInfoValue;
    if (AdditionalInfos)
      if (AdditionalInfos.length > 0) {
        let additionalInfoItem = AdditionalInfos.filter(
          (e) => e.key === additionalInfoKey
        );
        if (additionalInfoItem.length > 0)
          AdditionalInfoValue = additionalInfoItem[0].valueText;
      }
    return AdditionalInfoValue;
  }

  function getAdditionalInfoBooleanValue(additionalInfoKey) {
    let AdditionalInfoValue = false;
    if (AdditionalInfos)
      if (AdditionalInfos.length > 0) {
        let additionalInfoItem = AdditionalInfos.filter(
          (e) => e.key === additionalInfoKey
        );
        if (additionalInfoItem.length > 0)
          AdditionalInfoValue =
            additionalInfoItem[0].valueBoolean === "1" ? true : false;
      }
    return AdditionalInfoValue;
  }

  // Features

  function handleFeatureChange(e, Feature) {
    let value =
      resolvePersianAndArabicNumbers(e.target.value) ||
      resolvePersianAndArabicNumbers(e.currentTarget.value);
    let key = Feature.key;

    if (Features.length > 0) {
      let FeatureItem = Features.filter((e) => e.key === key);
      if (FeatureItem.length > 0) {
        setFeatures(
          Features.map((el) => (el.key === key ? { ...el, value: value } : el))
        );
      } else {
        setFeatures([...Features, { key: key, value: value }]);
      }
    } else {
      setFeatures([{ key: key, value: value }]);
    }
    if (errors.feature.length > 0) {
      setErrors({
        ...errors,
        feature: errors.feature.filter((e) => e.key !== Feature.key),
      });
    }

    if (key === "1" || key === "13")
      if (PricePerMeter) {
        let pricePerMeter = PricePerMeter.replace(/[,]+/g, "");
        let price = parseFloat(pricePerMeter) * parseFloat(value);
        setPrice(price > 0 ? getFormatedPrice(price.toString()) : "");
      }
  }

  function getFeatureValue(featureKey) {
    let featureValue;
    if (Features)
      if (Features.length > 0) {
        let featureItem = Features.filter((e) => e.key === featureKey);
        if (featureItem.length > 0) featureValue = featureItem[0].value;
      }
    return featureValue;
  }

  function yearOptions(lower, upper) {
    var arr = [];

    for (let i = upper; i >= parseInt(lower); i--) {
      arr.push(
        <MenuItem key={i} value={i} sx={{ width: "fit-content" }}>
          {" "}
          {i}
        </MenuItem>
      );
    }

    return arr;
  }

  function featureIsValid(isSaveClick) {
    const _errors = [];
    Features.forEach((element) => {
      let param = props.AllParams.feature.filter(
        (e) => e.key === element.key && !IGNORED_VALIDATION_KEYS.includes(e.key)
      );
      if (element.value === "") {
        if (param[0].isMandatory === "1")
          _errors.push({
            key: element.key,
            value: param[0].name + t("MandatoryField"),
          });
      } else {
        if (param[0]) {
          if (parseInt(element.value) < parseInt(param[0].lowerLimit))
            _errors.push({
              key: element.key,
              value: t("TheFieldValueMustBeGreaterThan", {
                name: param[0].name,
                value: param[0].lowerLimit,
              }),
            });

          if (parseInt(element.value) > parseInt(param[0].upperLimit))
            _errors.push({
              key: element.key,
              value: t("TheFieldValueMustBeGreaterThan", {
                name: param[0].name,
                value: param[0].upperLimit,
              }),
            });
        } else {
          // _errors.push({
          //   key: element.key,
          //   value: "حدث خطأ ",
          // });
        }
      }
    });
    if (isSaveClick) setErrors({ ...errors, feature: _errors });
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }

  function additionalInfoIsValid(isSaveClick) {
    const _errors = [];
    AdditionalInfos.forEach((element) => {
      let param = props.AllParams.additionalInfo.filter(
        (e) => e.key === element.key
      );

      switch (param?.[0]?.typeOfField) {
        case "1":
          if (element.valueBoolean === "1" && element.valueText === "")
            _errors.push({
              key: element.key,
              value: t("MandatoryField"),
            });
          break;
        case "3":
          if (element.valueBoolean === "")
            if (param[0].isMandatory === "1")
              _errors.push({
                key: element.key,
                value: t("MandatoryField"),
              });
          break;
        case "2":
          if (element.valueText === "")
            if (param[0].isMandatory === "1")
              _errors.push({
                key: element.key,
                value: t("MandatoryField"),
              });
          break;

        default:
          break;
      }
    });
    if (isSaveClick) setErrors({ ...errors, additionalInfo: _errors });
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }

  // Amenities
  function handleAmenityClick(target) {
    let value = target.currentTarget.value;
    if (Amenities.indexOf(value) > -1) {
      setAmenities(
        Amenities.filter(function (element) {
          return element !== value;
        })
      );
    } else setAmenities([...Amenities, value]);
  }

  // Plans
  const onDropPlan = (picture) => {
    let files = picture.length > 5 ? picture.slice(0, 5) : picture;
    DropImage(files, "plan");
  };

  function handleDeletePlan(target) {
    if (DEMO_VENDORS.includes(vendor)) {
      setFloorPlans((old) => [
        ...old?.filter((i) => i !== target?.currentTarget?.title),
      ]);
    } else {
      let photos = "";
      let deleted = target.currentTarget.title;
      const photosArr = Property.floorPlans.split(",");
      const index = photosArr.indexOf(deleted);
      if (index > -1) {
        photosArr.splice(index, 1);
      }
      photosArr.forEach((element) => {
        photos = photos + element + ",";
      });
      if (photos.length > 1) photos = photos.slice(0, -1);
      if (photos.length < 2) photos = "-1";

      let request = {
        floorPlans: photos,
        propertyId: props.propertyId,
        userId: props.userId,
      };
      savePropertyFloorPlans(request).then((property) => {
        if (property.resCode === 0) {
          setNewProperty({
            title: property.response,
            propertyId: props.propertyId,
            floorPlans: property.response,
          });
        } else {
          toast.error(property.resStr);
        }
      });
    }
  }

  // Price
  function handleSalePriceChange(target) {
    setPrice(getFormatedPrice(preventString(target.currentTarget.value)));
  }

  function handleLandSalePriceChange(target) {
    setPrice(getFormatedPrice(preventString(target.currentTarget.value)));

    let price = target.currentTarget.value.replace(/[,]+/g, "");
    let LandArea = getLandArea();
    let pricePerMeter = +(parseFloat(price) / parseFloat(LandArea)).toFixed(1);

    setPricePerMeter(
      pricePerMeter > 0 ? getFormatedPrice(pricePerMeter.toString()) : ""
    );
  }

  function handlePricePerMeterChange(target) {
    setPricePerMeter(
      getFormatedPrice(preventString(target.currentTarget.value))
    );

    let pricePerMeter = target.currentTarget.value.replace(/[,]+/g, "");
    let LandArea = getLandArea();
    let price = +(parseFloat(pricePerMeter) * parseFloat(LandArea)).toFixed(1);
    setPrice(price > 0 ? getFormatedPrice(price.toString()) : "");
  }

  function handleRentPriceChange(e, RentPrice) {
    let value = preventString(e.currentTarget.value)?.replace(/[,]+/g, "");
    let key = RentPrice.key;
    if (PriceRent.length > 0) {
      let RentPriceItem = PriceRent.filter((e) => e.key === key);
      if (RentPriceItem.length > 0) {
        setPriceRent(
          PriceRent.map((el) => (el.key === key ? { ...el, value: value } : el))
        );
      } else {
        setPriceRent([...PriceRent, { key: key, value: value }]);
      }
    } else {
      setPriceRent([{ key: key, value: value }]);
    }
  }

  function getRentPrice(key) {
    let rentPriceValue;
    if (PriceRent)
      if (PriceRent.length > 0) {
        let PriceRentItem = PriceRent.filter((e) => e.key === key);
        if (PriceRentItem.length > 0)
          if (PriceRentItem[0].value)
            rentPriceValue = getFormatedPrice(PriceRentItem[0].value);
      }
    return rentPriceValue;
  }

  function getLandArea() {
    let landArea = 0;
    let item;
    if (Property.propertyType === "1")
      item = Features.filter((e) => e.key === "1");
    if (Property.propertyType === "5")
      item = Features.filter((e) => e.key === "13");
    if (item.length > 0) landArea = item[0].value;
    return landArea;
  }

  function getFormatedPrice(price) {
    let value = price.replace(/[,]+/g, "");
    return value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  }

  function priceIsValid(isSaveClick) {
    const _errors = [];
    if (Property.listedFor === "2") {
      if (Price) {
        if (parseInt(Price.replace(/[,]+/g, "")) < 999) {
          let msg = t("TheSalesPriceIsUnreasonable");
          if (isSaveClick) {
            errors.price = msg;
            toast.error(msg);
          }
          _errors.push({ key: "2", error: msg });
        }
      } else {
        let msg = t("PleaseSpecifySellingPrice");
        if (isSaveClick) {
          errors.price = msg;
          toast.error(msg);
        }
        _errors.push({ key: "2", error: msg });
      }
    } else {
      let k = false;
      PriceRent.forEach((element) => {
        if (element.value) if (parseInt(element.value) > 0) k = true;
      });
      if (!k) {
        let msg = t("PleaseSpecifyRentalPrice");
        if (isSaveClick) {
          toast.error(msg);
          errors.price = msg;
        }
        _errors.push({ key: "2", error: errors.price });
      }
    }

    // setErrors({ ...errors, price: _errors });
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }

  async function handleSaveClick() {
    if (!featureIsValid(true)) {
      toast.error(t("CheckFeatures"));
      sScrollToFeatureRef();
      return;
    }
    if (!additionalInfoIsValid(true)) {
      toast.error(t("CheckAdditionalInfo"));
      sScrollToAdditionalInfoRef();
      return;
    }
    if (!priceIsValid(true)) {
      toast.error(t("CheckPrice"));
      return;
    }
    if (DEMO_VENDORS.includes(vendor)) {
      setOpenDemoSnackBar(true);
    } else {
      let Request = getRequestForSave(true, Images, Plans);
      setLoadingButton(true);

      let oldArabicDescription = getLocalizedText(oldDescription, "ar"),
        newArabicDescription = getLocalizedText(Description, "ar"),
        newDescription = Description;

      if (i18n.language === "ar" && autoTranslate === "1") {
        if (
          oldArabicDescription !== newArabicDescription &&
          newArabicDescription?.trim() !== ""
        ) {
          let newEnglishDescription = await translateText(newArabicDescription);
          newEnglishDescription =
            newEnglishDescription?.length >= 500
              ? newEnglishDescription?.substring(0, 500)
              : newEnglishDescription;
          newDescription = newArabicDescription + "~@~" + newEnglishDescription;
        }
      }

      addPropertyStep2({
        ...Request,
        description: newDescription,
      })
        .then((property) => {
          if (property.resCode === "0") {
            if (props.isEdit) {
              let path = isAdmin
                ? "/admin/property/my-listings"
                : "/my-listing";
              if (Property.status === "1") {
                // platformCompliance({
                //   userId: token,
                //   language: '0',
                //   propertyId: Property.propertyId,
                //   operationType: 'UpdateAd',
                //   operationReason: 'Other'
                // }).finally(() => {
                //   history(path);
                //   setLoadingButton(false);
                //   toast.success(property.resStr);
                // });
                history(path);
                setLoadingButton(false);
                toast.success(property.resStr);
              } else {
                history(path);
                toast.success(property.resStr);
                setLoadingButton(false);
              }
            } else {
              confirmAlert(confirmAlertOptions);
            }
          } else if (property.resCode === 22) {
            const _errors = [];
            _errors.push({
              key: "4",
              value: property.resStr,
            });
            setErrors({ ...errors, additionalInfo: _errors });
            toast.error(property.resStr);
            setLoadingButton(false);
          } else {
            toast.error(property.resStr);
            setLoadingButton(false);
          }
        })
        .catch((error) => {
          toast.error(error);
          setLoadingButton(false);
        });
    }
  }

  function getRequestForSave(isSaveClick, images, floorPlans) {
    let Request = {
      userId: props.userId,
      language: i18n.language === "ar" ? "0" : "1",
      propertyId: Property.propertyId,
      listedFor: Property.listedFor,
    };
    if (images) Request = { ...Request, photos: images };
    if (floorPlans) Request = { ...Request, floorPlans: floorPlans };

    let rooms = "";
    if (Rooms)
      if (Rooms.length > 0) {
        Rooms.forEach((element) => {
          rooms = rooms + element.key + ":" + element.value + ",";
        });
        rooms = rooms.slice(0, -1);
      }
    if (rooms) Request = { ...Request, rooms: rooms };

    if (featureIsValid(isSaveClick)) {
      let features = "";
      if (Features.length > 0) {
        Features.forEach((element) => {
          if (element.value)
            features = features + element.key + ":" + element.value + ",";
        });
        features = features.slice(0, -1);
      }
      if (features) Request = { ...Request, features: features };
    }

    let additionalInfos = "";
    if (AdditionalInfos.length > 0) {
      AdditionalInfos.forEach((element) => {
        let param = props.AllParams.additionalInfo.filter(
          (e) => e.key === element.key
        );

        switch (param?.[0]?.typeOfField) {
          case "1":
            let boolToTextValue =
              getAdditionalInfoBooleanValue(element.key) === true ? "1" : "0";
            let TextValue =
              boolToTextValue === "1"
                ? getAdditionalInfoTextValue(element.key)
                : "";
            additionalInfos =
              additionalInfos +
              element.key +
              ":" +
              boolToTextValue +
              "^" +
              TextValue +
              ",";
            break;
          case "2":
            additionalInfos =
              additionalInfos + element.key + ":^" + element.valueText + ",";
            break;

          case "3":
            additionalInfos =
              additionalInfos + element.key + ":" + element.valueBoolean + "^,";
            break;

          default:
            break;
        }
      });
      additionalInfos = additionalInfos.slice(0, -1);
    }
    if (additionalInfos)
      Request = { ...Request, additionalInfo: additionalInfos };

    let amenities = "";
    if (Amenities.length > 0) {
      Amenities.forEach((element) => {
        amenities = amenities + element + ",";
      });
      amenities = amenities.slice(0, -1);
    }
    if (amenities) Request = { ...Request, amenities: amenities };

    if (Description) Request = { ...Request, description: Description };

    if (priceIsValid(isSaveClick)) {
      let defaultPriceType = "1";

      if (Property.listedFor === "2") {
        let price = Price.replace(/[,]+/g, "");
        let pricePerMeter = PricePerMeter
          ? PricePerMeter.replace(/[,]+/g, "")
          : "0";
        Request = {
          ...Request,
          price: price,
          pricePerSquareMeter: pricePerMeter,
          defaultPriceType: defaultPriceType,
        };
      } else {
        let price = "";
        PriceRent.forEach((element) => {
          if (element.value)
            price = price + element.key + ":" + element.value + ",";
        });
        if (price) {
          price = price.slice(0, -1);
          defaultPriceType = price.charAt(0);
          Request = {
            ...Request,
            price: price,
            defaultPriceType: defaultPriceType,
          };
        }
      }
    }
    return Request;
  }

  function callPublishPropertyAPI() {
    publishMyListing({
      propertyIds: props.propertyId,
      userId: props.userId,
      isPublish: "1",
    })
      .then((property) => {
        if (property.resCode === 0) {
          // logEvent(analytics, "add_property", { platform: "website" });
          let path = isAdmin ? "/admin/property/my-listings" : "/my-listing";
          // platformCompliance({
          //   userId: token,
          //   language: '0',
          //   propertyId: Property.propertyId,
          //   operationType: 'DisplayAd',
          //   operationReason: 'Other'
          // }).finally(() => {
          //   history(path);
          //   setLoadingButton(false);
          //   toast.success(property.resStr);
          // });
          history(path);
          setLoadingButton(false);
          toast.success(property.resStr);
        } else {
          toast.error(property.resStr);
          setLoadingButton(false);
        }
      })
      .catch((error) => {
        toast.error(error);
        setLoadingButton(false);
      });
  }

  const confirmAlertOptions = {
    customUI: ({ onClose }) => {
      return (
        <div className='custom-ui'>
          <h3>{t("ConfirmThePublicationOfTheRealEstateAD")}</h3>
          <h5 style={{ marginBottom: "15px" }}>
            {t("WouldYouLikeToPublishTheRealEstateADNow")}
          </h5>
          <p
            style={{
              marginBottom: "25px",
              color: "var(--main-color-one)",
              fontSize: "17px",
            }}>
            {t("TotalNumberOfPublishedRealEstateADsThatAreAllowedToBeAdded")}:{" "}
            {" " + currentPlan?.allowedPublishedProperties + " "}
            <br />
            {t("NumberOfADsPublishedByYou")}:{" "}
            {" " + +currentPlan?.allowedPublishedProperties - +propertiesLeft}
          </p>
          <p style={{ marginBottom: "15px", color: "#d00404" }}>
            {t(
              "PleaseNoteThatPublishingThisADWillDeductFromYourRealEstateAdvertisingBalance"
            )}
          </p>

          <div className='btns-wrapper'>
            <button
              className='outlined-btn px-3'
              onClick={() => {
                onClose();
                setLoadingButton(false);
              }}>
              {t("NoIWouldLikeToModify")}
            </button>
            <button
              className='primary-btn px-4'
              onClick={() => {
                callPublishPropertyAPI();
                onClose();
              }}>
              {t("OK")}
            </button>
          </div>
        </div>
      );
    },
    closeOnEscape: true,
    closeOnClickOutside: true,
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "overlay-custom-class-name",
  };

  return (
    <div className='px-1'>
      <SubHeader
        withSteps
        steps={[
          t("RealEstateAdvertisingControls"),
          t("VerifyAD"),
          t("ReviewInformation"),
          t("AdditionalData"),
        ]}
        pageTitle={
          props.isEdit
            ? `${t("Edit")} ${Property.propertyTypeName || t("RealEstateAD1")}
        ${
          Property.listedFor === "2"
            ? t("ForSale")
            : Property.listedFor === "1"
            ? t("ForRent")
            : ""
        }`
            : `${t("Add")} ${Property.propertyTypeName || t("RealEstateAD1")}
        ${
          Property.listedFor === "2"
            ? t("ForSale")
            : Property.listedFor === "1"
            ? t("ForRent")
            : ""
        }`
        }
        // onOK={handleSaveClick}
        completedSteps={props.isEdit ? [0, 1, 2, 3] : [0, 1, 2]}
        currentStep={3}
        OKElement={<></>}
      />

      <div className='custom-card'>
        <div className='card-header'>
          <h4>{t("Images")}</h4>
          <h6>{t("AddImagesToYourRealEstateAD")}</h6>
        </div>
        <div className='card-body'>
          <DragAndDropFileUploader
            ImageOnly
            singleFile={false}
            OnOK={onDrop}
            uploading={uploadedPicMsg}
            id='property-images'
            extra={t("YouCanUploadMaximumOf30Images")}
          />

          {/* {uploadedPicMsg ? (
            <img
              src={publicUrl + "assets/img/loding.gif"}
              alt='loading'></img>
          ) : (
            ""
          )} */}

          {uploadedPicMsg && (
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                position: "relative",
                display: "inline-flex",
              }}>
              <LinearProgress
                variant='determinate'
                value={uploadedImagesProgress}
                sx={{ width: "100%", height: "20px" }}
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <Typography
                  variant='caption'
                  component='div'
                  sx={{ fontWeight: 700, color: "#fff" }}>
                  {`${Math.round(uploadedImagesProgress)}%`}
                </Typography>
              </Box>
            </Box>
          )}

          {((DEMO_VENDORS.includes(vendor) && photosNew?.length > 0) ||
            (!DEMO_VENDORS.includes(vendor) &&
              Property?.photosNew &&
              Property?.photosNew?.split(",")?.length > 0)) && (
            <div className='uploaded-images-wrapper'>
              <h4>
                {t("UploadedImages", {
                  count: DEMO_VENDORS.includes(vendor)
                    ? photosNew?.length
                    : Property?.photosNew &&
                      Property.photosNew.split(",").length,
                })}
              </h4>
              <Swiper
                spaceBetween={32}
                slidesPerView={5}
                key={i18n.language + "ii"}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  450: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  680: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1000: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1250: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                }}
                scrollbar={{ draggable: true, el: ".swiper-scrollbar" }}
                modules={[Scrollbar, Keyboard]}
                keyboard={{ enabled: true }}
                speed={2600}>
                {(DEMO_VENDORS.includes(vendor)
                  ? photosNew
                  : Property?.photosNew && Property?.photosNew?.split(",")
                )?.map((image, index) => (
                  <SwiperSlide key={index} className='image-item'>
                    <span
                      className={`custom-btn ${index === 0 ? "selected" : ""}`}>
                      {index === 0 ? (
                        <span>{t("CoverImage")}</span>
                      ) : (
                        <>
                          <Check />
                          <span title={image} onClick={handleSetDefaultImage}>
                            {t("MakeItCoverPhoto")}
                          </span>
                        </>
                      )}
                    </span>

                    <span
                      className='del-icon'
                      title={image}
                      onClick={handleDeleteImage}>
                      <Trash />
                    </span>
                    <img src={image} alt='' className='radius2' />
                    {!DEMO_VENDORS.includes(vendor) && (
                      <input
                        id={index + ""}
                        key={index + ""}
                        title={image}
                        onChange={onTitleChange}
                        placeholder={t("ImageDescription")}
                        className='form-control'
                        value={getTitle(image) || ""}
                        style={{
                          width: "calc(100% - 26px)",
                          borderRadius: "0 0 8px 8px",
                        }}
                      />
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className='swiper-scrollbar'></div>
            </div>
          )}
        </div>
      </div>
      {(currentPlan?.canAddVideo === "1" ||
        currentPlan?.isTryWlMode === "1") && (
        <div className='custom-card'>
          <div className='card-header'>
            <h4>{t("Videos")}</h4>
            <h6>{t("AddVideosAboutTheProperty")}</h6>
          </div>
          <div className='card-body'>
            <DragAndDropFileUploader
              VideosOnly
              singleFile={false}
              OnOK={onDropVideos}
              uploading={uploadingVideo}
              id='property-videos'
              extra={t("YouCanUploadMaximumOf3Videos")}
            />

            {uploadingVideo && (
              <Box
                sx={{
                  width: "100%",
                  marginTop: "30px",
                  position: "relative",
                  display: "inline-flex",
                }}>
                <LinearProgress
                  variant='determinate'
                  value={uploadedVideosProgress}
                  sx={{ width: "100%", height: "20px" }}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <Typography
                    variant='caption'
                    component='div'
                    sx={{ fontWeight: 700, color: "#fff" }}>
                    {`${Math.round(uploadedVideosProgress)}%`}
                  </Typography>
                </Box>
              </Box>
            )}

            {((DEMO_VENDORS.includes(vendor) && Videos?.length > 0) ||
              (!DEMO_VENDORS.includes(vendor) && Videos?.length > 0)) && (
              <>
                {" "}
                <div className='uploaded-images-wrapper'>
                  <h4>
                    {t("UploadedVideos")} ({Videos?.length})
                  </h4>
                  <Swiper
                    spaceBetween={32}
                    slidesPerView={5}
                    key={i18n.language + "ii"}
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                      450: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      680: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      1000: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                      },
                      1250: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                      },
                    }}
                    scrollbar={{ draggable: true, el: ".swiper-scrollbar" }}
                    modules={[Scrollbar, Keyboard]}
                    keyboard={{ enabled: true }}
                    speed={2600}>
                    {Videos?.map((video, index) => (
                      <SwiperSlide
                        key={index}
                        className='image-item video-item d-flex justify-content-center'>
                        <div
                          className='youtube-icon'
                          onClick={() =>
                            setOpenVideoDialog({
                              visible: true,
                              url: video.video_url,
                            })
                          }>
                          <svg
                            width='31'
                            height='32'
                            viewBox='0 0 21 22'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M1.63861 10.764V6.70324C1.63861 1.66145 5.20893 -0.403178 9.57772 2.11772L13.1024 4.14812L16.6271 6.17853C20.9959 8.69942 20.9959 12.8287 16.6271 15.3496L13.1024 17.38L9.57772 19.4104C5.20893 21.9313 1.63861 19.8666 1.63861 14.8249V10.764Z'
                              strokeWidth='1.5'
                              strokeMiterlimit='10'
                              strokeLinecap='round'
                              strokeLinejoin='round'></path>
                          </svg>
                        </div>
                        <span
                          className='del-icon'
                          onClick={() => handleDeleteVideo(video)}>
                          <Trash />
                        </span>
                        <img
                          src={
                            video.thumbnail ||
                            `${publicUrl}assets/img/icons/mp4-icon.png`
                          }
                          alt='video-img'
                          loading='lazy'
                          className='model-img'
                          style={{
                            objectFit: video.thumbnail ? "cover" : "contain",
                            width: video.thumbnail ? "100%" : "170px",
                            height: video.thumbnail ? "100%" : "115px",
                          }}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div className='swiper-scrollbar'></div>
                </div>
                <Dialog
                  onClose={() =>
                    setOpenVideoDialog({ visible: false, url: "" })
                  }
                  maxWidth={"lg"}
                  style={{ zIndex: 9999999 }}
                  sx={{
                    "& .MuiPaper-root": {
                      margin: 0,
                      padding: 0,
                      zIndex: 9999999,
                      width: "calc(100vw - 10%)",
                      borderRadius: "12px",
                      border: 0,
                    },
                  }}
                  open={openVideoDialog.visible}>
                  <DialogContent
                    sx={{
                      padding: 0,
                      background: "var(--main-color-one)",
                      borderRadius: "12px",
                      border: 0,
                    }}>
                    <video
                      style={{ height: "500px", width: "100%", border: 0 }}
                      controls>
                      <source src={openVideoDialog.url} type='video/mp4' />
                      Your browser does not support the video tag.
                    </video>
                  </DialogContent>
                </Dialog>
              </>
            )}
          </div>
        </div>
      )}
      {PropertyParams.room?.filter((i) => !HIDDEN_ROOMS.includes(i.key))
        .length > 0 && (
        <div className='custom-card' style={{ minHeight: "auto" }}>
          <div className='card-header'>
            <h4>{t("Details")}</h4>
            <h6>{t("AddTheFollowingDetailsAccurately")}</h6>
          </div>
          <div className='card-body row model-details m-0 '>
            {PropertyParams.room.map(
              (Room, index) =>
                !HIDDEN_ROOMS.includes(Room.key) && (
                  <div
                    key={index}
                    className='col-12 col-md-6 mb-4 mb-lg-0 room-wrapper'
                    style={{
                      borderLeft:
                        PropertyParams.room.length > 1 && index % 2 === 0
                          ? "1px solid rgba(0, 0, 0, 0.12)"
                          : "none",
                    }}>
                    <div className='room'>
                      <div className='col-12 '>{Room.name}</div>
                      <div className='row col-12 '>
                        <div className='col-8 col-sm-9 col-xl-10'>
                          {/* <input
                        id={index + 'range'}
                        type='range'
                        min={Room.lowerLimit}
                        max={Room.upperLimit}
                        onChange={(e) => {
                          handleRoomChange(e, Room);
                        }}
                        value={getRoomValue(Room.key) || Room.lowerLimit}
                      /> */}
                          <ThemeProvider
                            theme={(outerTheme) => ({
                              ...outerTheme,
                              direction: "ltr",
                            })}>
                            <Slider
                              id={index + "range"}
                              value={
                                +getRoomValue(Room.key) || +Room.lowerLimit
                              }
                              getAriaValueText={(value) => `${value}`}
                              step={1}
                              marks
                              min={+Room.lowerLimit}
                              max={+Room.upperLimit}
                              sx={{ width: "100%", flex: 1 }}
                              valueLabelDisplay='auto'
                              onChange={(e) => handleRoomChange(e, Room)}
                            />
                          </ThemeProvider>
                        </div>

                        <div className='col val'>
                          <input
                            id={index + "input"}
                            type='number'
                            min={Room.lowerLimit}
                            max={Room.upperLimit}
                            onChange={(e) => {
                              handleRoomChange(e, Room);
                            }}
                            value={getRoomValue(Room.key) || Room.lowerLimit}
                          />
                          {/* {getRoomValue(Room.key)} */}
                        </div>
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      )}

      {/* <div className="cards-wrapper details-wrapper align-items-start"> */}
      {PropertyParams.feature &&
        PropertyParams.feature.filter((i) => !HIDDEN_FEATURES.includes(i.key))
          .length > 0 && (
          <div
            className='custom-card'
            style={{ minHeight: "auto" }}
            ref={featureRef}>
            <div className='card-header'>
              <h4>{t("Specifications")}</h4>
              <h6>{t("AddTheFollowingSpecificationsAccurately")}</h6>
            </div>
            <div className='card-body row model-details m-0'>
              {PropertyParams.feature
                .filter((i) => !HIDDEN_FEATURES.includes(i.key))
                .map((Feature, index) => (
                  <div className='col-12 col-md-6 col-lg-6 mb-4' key={index}>
                    {Feature.key !== "7" ? (
                      Feature.key === "6" ? (
                        <>
                          <FormControl className='custom-select year-select'>
                            <InputLabel htmlFor='year1'>
                              {Feature.name}
                            </InputLabel>
                            <Select
                              labelId='year1'
                              // InputLabelProps={{
                              //   shrink: true,
                              // }}
                              required={
                                Feature.isMandatory === "1" ? true : false
                              }
                              name='year1'
                              value={getFeatureValue(Feature.key) || ""}
                              sx={{ width: "100%" }}
                              onChange={(event) => {
                                handleFeatureChange(event, Feature);
                              }}
                              label={Feature.name}
                              input={
                                <OutlinedInput
                                  notched
                                  label={Feature.name}
                                  id='year1'
                                />
                              }
                              inputProps={{
                                name: "year1",
                              }}>
                              {yearOptions(
                                Feature.lowerLimit,
                                Feature.upperLimit
                              )}
                            </Select>
                          </FormControl>
                          {errors.feature.length > 0 &&
                            errors.feature.filter((e) => e.key === Feature.key)
                              .length > 0 && (
                              <Alert severity='error' className='custom-alert'>
                                {t("PleaseSelectTheYearOfConstruction")}
                              </Alert>
                            )}
                        </>
                      ) : (
                        <>
                          <TextField
                            label={Feature.name}
                            sx={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id={index + ""}
                            placeholder={
                              Feature.lowerLimit + " - " + Feature.upperLimit
                            }
                            required={Feature.isMandatory === "1"}
                            autoComplete='off'
                            value={getFeatureValue(Feature.key) || ""}
                            inputProps={{
                              min: Feature.lowerLimit,
                              max: Feature.upperLimit,
                            }}
                            size='small'
                            type='number'
                            onChange={(e) => {
                              handleFeatureChange(e, Feature);
                            }}
                          />
                          {errors.feature.length > 0 &&
                            errors.feature.filter((e) => e.key === Feature.key)
                              .length > 0 && (
                              <Alert severity='error' className='custom-alert'>
                                {
                                  errors.feature.filter(
                                    (e) => e.key === Feature.key
                                  )[0].value
                                }
                              </Alert>
                            )}
                        </>
                      )
                    ) : (
                      <>
                        <FormControl className='custom-select'>
                          <InputLabel htmlFor='year2'>
                            {Feature.name}
                          </InputLabel>
                          <Select
                            name='year2'
                            value={getFeatureValue(Feature.key) || ""}
                            sx={{ width: "100%", direction: "rtl" }}
                            // InputLabelProps={{ shrink: true }}
                            input={
                              <OutlinedInput
                                notched
                                label={Feature.name}
                                id='year2'
                              />
                            }
                            onChange={(event) => {
                              handleFeatureChange(event, Feature);
                            }}
                            required={
                              Feature.isMandatory === "1" ? true : false
                            }
                            inputProps={{ name: "year2" }}>
                            <MenuItem key='North' value='North'>
                              {t("North")}
                            </MenuItem>
                            <MenuItem key='South' value='South'>
                              {t("South")}
                            </MenuItem>
                            <MenuItem key='East' value='East'>
                              {t("East")}
                            </MenuItem>
                            <MenuItem key='West' value='West'>
                              {t("West")}
                            </MenuItem>
                            <MenuItem key='North-West' value='North-West'>
                              {t("NorthWest")}
                            </MenuItem>
                            <MenuItem key='North-East' value='North-East'>
                              {t("NorthEast")}
                            </MenuItem>
                            <MenuItem key='South-East' value='South-East'>
                              {t("SouthEast")}
                            </MenuItem>
                            <MenuItem key='South-West' value='South-West'>
                              {t("SouthWest")}
                            </MenuItem>
                          </Select>
                        </FormControl>
                        {errors.feature.length > 0 &&
                          errors.feature.filter((e) => e.key === Feature.key)
                            .length > 0 && (
                            <Alert severity='error' className='custom-alert'>
                              {t("PleaseSelectTheInterface")}
                            </Alert>
                          )}
                      </>
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}
      {PropertyParams.amenity.length > 0 && (
        <div className='custom-card'>
          <div className='card-header'>
            <h4>{t("FacilitiesAndFeatures")}</h4>
            <h6>{t("SelectTheFacilitiesAndFeaturesAvailableInTheProperty")}</h6>
          </div>
          <div className='card-body'>
            <div className='fields-wrapper fields-wrapper-row '>
              {props.AllParams.amenity.map((item, i) => (
                <div className='col' key={i}>
                  <Switch
                    value={item.key}
                    checked={Amenities.filter((e) => e === item.key).length > 0}
                    onChange={handleAmenityClick}
                  />
                  <span>{item.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* </div> */}

      {PropertyParams?.additionalInfo &&
        PropertyParams?.additionalInfo?.filter(
          (i) => ![...HIDDEN_ADITTIONAL_INFO_FOR_ADD, "23"].includes(i.key + "")
        )?.length > 0 && (
          <div
            className='custom-card additional'
            style={{ minHeight: "auto" }}
            ref={additionalInfoRef}>
            <div className='card-header'>
              <h4>{t("AdditionalInfo")}</h4>
              <h6>{t("SpecifyAdditionalInformation")}</h6>
            </div>
            <div className='card-body'>
              <div className='fields-wrapper'>
                {PropertyParams.additionalInfo
                  .filter(
                    (i) =>
                      ![...HIDDEN_ADITTIONAL_INFO_FOR_ADD, "23"].includes(
                        i.key + ""
                      )
                  )
                  .map(
                    (AdditionalInfo, index) =>
                      AdditionalInfo.typeOfField !== "3" &&
                      (AdditionalInfo.typeOfField === "2" ? (
                        <div
                          key={AdditionalInfo.key}
                          className='row flex-column col-12 m-0 p-0'>
                          <div>
                            {AdditionalInfo.name}{" "}
                            {AdditionalInfo.isMandatory === "1" ? (
                              <b
                                style={{
                                  color: "#D00404",
                                  display: "inline-block",
                                }}>
                                *
                              </b>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className='col-12 mt-2 m-0 p-0'>
                            <input
                              id={index + ""}
                              required
                              className='form-control w-100'
                              onChange={(e) => {
                                handleAdditionalInfoTextChange(
                                  e,
                                  AdditionalInfo
                                );
                              }}
                              value={
                                getAdditionalInfoTextValue(
                                  AdditionalInfo.key
                                ) || ""
                              }
                            />

                            {errors.additionalInfo.length > 0 &&
                              errors.additionalInfo.filter(
                                (e) => e.key === AdditionalInfo.key
                              ).length > 0 && (
                                <Alert
                                  severity='error'
                                  className='custom-alert'>
                                  {
                                    errors.additionalInfo.filter(
                                      (e) => e.key === AdditionalInfo.key
                                    )[0].value
                                  }
                                </Alert>
                              )}
                          </div>
                        </div>
                      ) : (
                        <div
                          key={index}
                          className='row flex-column col-12 m-0 p-0'>
                          <div className='field col-12 m-0'>
                            <label>{AdditionalInfo.name}</label>
                            <Stack
                              direction='row'
                              alignItems='center'
                              sx={{ justifyContent: "center" }}>
                              <span style={{ fontSize: "12px" }}>
                                {AdditionalInfo.key === "4"
                                  ? t("Electronic")
                                  : t("Yes")}
                              </span>
                              <Switch
                                checked={getAdditionalInfoBooleanValue(
                                  AdditionalInfo.key
                                )}
                                onChange={() => {
                                  handleAdditionalInfoBooleanChange(
                                    !getAdditionalInfoBooleanValue(
                                      AdditionalInfo.key
                                    ),
                                    AdditionalInfo
                                  );
                                }}
                              />
                              <span style={{ fontSize: "12px" }}>
                                {AdditionalInfo.key === "4"
                                  ? t("Paper")
                                  : t("No")}
                              </span>
                            </Stack>
                          </div>

                          {getAdditionalInfoBooleanValue(
                            AdditionalInfo.key
                          ) && (
                            <div className='col-12 m-0 p-0'>
                              <textarea
                                rows={AdditionalInfo.key === "4" ? 1 : 5}
                                maxLength={
                                  AdditionalInfo.key === "4" ? 10 : 500
                                }
                                id={index + ""}
                                placeholder={
                                  AdditionalInfo.key === "4"
                                    ? t("EnterTheAuthorizationNumber")
                                    : t("EnterAdditionalInformation")
                                }
                                className='form-control custom-input'
                                onChange={(e) => {
                                  handleAdditionalInfoTextChange(
                                    e,
                                    AdditionalInfo
                                  );
                                }}
                                value={
                                  getAdditionalInfoTextValue(
                                    AdditionalInfo.key
                                  ) || ""
                                }
                              />
                            </div>
                          )}
                          {errors.additionalInfo.length > 0 &&
                            errors.additionalInfo.filter(
                              (e) => e.key === AdditionalInfo.key
                            ).length > 0 && (
                              <Alert severity='error' className='custom-alert'>
                                {
                                  errors.additionalInfo.filter(
                                    (e) => e.key === AdditionalInfo.key
                                  )[0].value
                                }
                              </Alert>
                            )}
                        </div>
                      ))
                  )}

                {/* <div className="col-12 row fixed-additional p-0 m-0">
                  {PropertyParams.additionalInfo.filter(i=> !HIDDEN_ADITTIONAL_INFO_FOR_ADD.includes(i.key)).map(
                    (AdditionalInfo, index) => (
                      AdditionalInfo.typeOfField === "3" && (
                        <div
                          key={index}
                          className='field size-30'>
                          <>
                            <label>{AdditionalInfo.name}</label>
                            <Stack
                              direction='row'
                              alignItems='center'
                              sx={{ justifyContent: "center" }}>
                              <span style={{ fontSize: '12px' }}>نعم</span>
                              <Switch
                                checked={getAdditionalInfoBooleanValue(
                                  AdditionalInfo.key
                                )}
                                onChange={() => {
                                  handleAdditionalInfoBooleanChange(
                                    !getAdditionalInfoBooleanValue(
                                      AdditionalInfo.key
                                    ),
                                    AdditionalInfo
                                  );
                                }}
                              />
                              <span style={{ fontSize: '12px' }}>لا</span>
                            </Stack>
                            {errors.additionalInfo.length > 0 &&
                              errors.additionalInfo.filter(
                                (e) => e.key === AdditionalInfo.key
                              ).length > 0 && (
                                <Alert severity="error" className="custom-alert">
                                  الرجاء الإختيار
                                </Alert>
                              )}
                          </>
                
                        </div>
                      )
                    )
                  )}
                </div> */}
              </div>
            </div>
          </div>
        )}

      <div className='custom-card'>
        <div className='card-header'>
          <h4>{t("PlansOptional")}</h4>
          <h6>{t("AddThePropertyPlansAndDeeds")}</h6>
        </div>
        <div className='card-body'>
          <DragAndDropFileUploader
            OnOK={onDropPlan}
            singleFile={false}
            id='property-plans'
            uploading={uploadedPlanMsg}
            extra={t("YouCanUploadMaximumOf5Files")}
          />
          {/* {uploadedPlanMsg ? (
            <img
              src={publicUrl + "assets/img/loding.gif"}
              alt='loading'></img>
          ) : (
            ""
          )} */}
          {uploadedPlanMsg && (
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                position: "relative",
                display: "inline-flex",
              }}>
              <LinearProgress
                variant='determinate'
                value={uploadedPlansProgress}
                sx={{ width: "100%", height: "20px" }}
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <Typography
                  variant='caption'
                  component='div'
                  sx={{ fontWeight: 700, color: "#fff" }}>
                  {`${Math.round(uploadedPlansProgress)}%`}
                </Typography>
              </Box>
            </Box>
          )}

          {((DEMO_VENDORS.includes(vendor) && floorPlans?.length > 0) ||
            (!DEMO_VENDORS.includes(vendor) &&
              Property?.floorPlans &&
              Property?.floorPlans?.split(",")?.length > 0)) && (
            <div className='uploaded-images-wrapper'>
              <h4>
                {t("UploadedPlans")} (
                {DEMO_VENDORS.includes(vendor)
                  ? floorPlans?.length
                  : Property?.floorPlans &&
                    Property.floorPlans.split(",").length}
                )
              </h4>
              <Swiper
                spaceBetween={32}
                slidesPerView={5}
                key={i18n.language + "ii"}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  450: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  680: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1000: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1250: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                }}
                scrollbar={{ draggable: true, el: ".swiper-scrollbar2" }}
                modules={[Scrollbar, Keyboard]}
                keyboard={{ enabled: true }}
                speed={2600}>
                {(DEMO_VENDORS.includes(vendor)
                  ? floorPlans
                  : Property?.floorPlans && Property?.floorPlans?.split(",")
                )?.map((image, index) => (
                  <SwiperSlide key={index} className='image-item'>
                    <span
                      className='del-icon'
                      title={image}
                      onClick={handleDeletePlan}>
                      <Trash />
                    </span>
                    {image.includes(".pdf") ||
                    image?.startsWith("data:application/pdf") ? (
                      <img
                        src={`${publicUrl}assets/img/icons/Icon_pdf_file.png`}
                        alt={`floor-img`}
                        loading='lazy'
                        className='model-img'
                        style={{ objectFit: "contain" }}
                      />
                    ) : (
                      <img src={image} alt='' />
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className='swiper-scrollbar2'></div>
            </div>
          )}
        </div>
      </div>

      <div className='custom-card'>
        <div className='card-header'>
          <h4>{t("PropertyDescriptionOptional")}</h4>
          <h6>{t("WriteComprehensiveDescriptionOfTheProperty")}</h6>
        </div>
        <div className='card-body'>
          <div className='fields-wrapper'>
            <TextField
              label={t("PropertyDescription")}
              sx={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ maxLength: 500 }}
              size='small'
              multiline
              rows={6}
              value={getLocalizedText(Description, i18n.language)}
              onChange={(v) => {
                let arabicPart = getLocalizedText(Description, "ar");
                let englishPart = getLocalizedText(Description, "en");
                if (i18n.language === "ar")
                  arabicPart =
                    v.currentTarget.value?.length >= 500
                      ? v.currentTarget.value?.substring(0, 500)
                      : v.currentTarget.value;
                else
                  englishPart =
                    v.currentTarget.value?.length >= 500
                      ? v.currentTarget.value?.substring(0, 500)
                      : v.currentTarget.value;
                let text =
                  arabicPart +
                  (englishPart?.length > 0 ? "~@~" + englishPart : "");
                setDescription(resolvePersianAndArabicNumbers(text));
              }}
            />
            <h6 className='rightalign'>
              {Description
                ? parseInt(
                    getLocalizedText(Description, i18n.language)?.length
                  ) + "/500"
                : "0/500"}
            </h6>
          </div>
        </div>
      </div>

      {Property?.latitude === "24" && Property?.longitude === "24" && (
        <>
          <div
            className='custom-card with-switcher'
            style={{ minHeight: "auto" }}>
            <div className='card-header' style={{ borderRadius: "16px" }}>
              <div>
                <h4>{t("LocateTheProperty")}</h4>
                <h6>{t("YouMustLocateThePropertyOnTheMap")}</h6>
              </div>
              <button
                className='primary-btn d-flex my-0 justify-content-center px-3'
                onClick={() =>
                  setOpenMapDialog({ visible: true, property: Property })
                }>
                {t("Locate")}
              </button>
            </div>
          </div>
          <SetPropertyLocationDialog
            openMapDialog={openMapDialog}
            onOK={getPropertyData}
            setOpenMapDialog={setOpenMapDialog}
          />
        </>
      )}

      <div className='custom-card price'>
        <div className='card-header'>
          <h4>{t("Price")}</h4>
          <h6>
            {" "}
            {Property.listedFor === "2"
              ? t("PriceDescriptionForSell")
              : t("PriceDescriptionForRent")}
          </h6>
        </div>
        <div className='card-body row model-details m-0'>
          {Property.listedFor === "2" ? (
            //sale

            <>
              {Property.propertyType === "1" ? (
                //Land
                <>
                  <div className='col-12 col-md-6 col-lg-4 mb-4'>
                    <TextField
                      label={t("MeterPrice")}
                      sx={{ width: "100%" }}
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required={true}
                      value={PricePerMeter}
                      size='small'
                      onChange={handlePricePerMeterChange}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    {errors.pricePerMeter && (
                      <Alert severity='error' className='custom-alert'>
                        {errors.pricePerMeter}
                      </Alert>
                    )}
                  </div>
                  <div className='col-12 col-md-6 col-lg-4 mb-4'>
                    <TextField
                      label={t("TotalPrice")}
                      sx={{ width: "100%" }}
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required={true}
                      autoComplete='off'
                      value={Price}
                      size='small'
                      onChange={handleLandSalePriceChange}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    {errors.price && (
                      <Alert severity='error' className='custom-alert'>
                        {errors.price}
                      </Alert>
                    )}
                  </div>
                </>
              ) : (
                <>
                  {Property.propertyType === "5" ? (
                    <>
                      <div className='col-12 col-md-6 col-lg-4 mb-4'>
                        <TextField
                          label={t("MeterPrice")}
                          sx={{ width: "100%" }}
                          disabled
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required={true}
                          autoComplete='off'
                          value={PricePerMeter}
                          size='small'
                          onChange={handlePricePerMeterChange}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors.pricePerMeter && (
                          <Alert severity='error' className='custom-alert'>
                            {errors.pricePerMeter}
                          </Alert>
                        )}
                      </div>

                      <div className='col-12 col-md-6 col-lg-4 mb-4'>
                        <TextField
                          label={t("TotalPrice")}
                          sx={{ width: "100%" }}
                          disabled
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required={true}
                          autoComplete='off'
                          value={Price}
                          size='small'
                          onChange={handleLandSalePriceChange}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors.Price && (
                          <Alert severity='error' className='custom-alert'>
                            {errors.Price}
                          </Alert>
                        )}
                      </div>
                    </>
                  ) : (
                    // All other types
                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                      <TextField
                        label={t("TotalPrice")}
                        sx={{ width: "100%" }}
                        disabled
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required={true}
                        autoComplete='off'
                        value={Price}
                        size='small'
                        onChange={handleSalePriceChange}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {errors.price && (
                        <Alert severity='error' className='custom-alert'>
                          {errors.price}
                        </Alert>
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            //rent
            <>
              {PriceRent.length > 0 ? (
                PriceRent.map((item, i) => (
                  <div key={i} className='col-12 col-md-6 col-lg-4 mb-4'>
                    <TextField
                      label={item.name}
                      disabled={item.key === "1"}
                      sx={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required={item.key === "1"}
                      autoComplete='off'
                      value={getRentPrice(item.key) || ""}
                      size='small'
                      onChange={(e) => {
                        handleRentPriceChange(e, item);
                      }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    {errors.price && (
                      <Alert severity='error' className='custom-alert'>
                        {errors.price}
                      </Alert>
                    )}
                  </div>
                ))
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
      <div className='pb-5 mb-5'>
        <LoadingButton
          classes='primary-btn px-5 mx-auto mb-5'
          style={{ minWidth: "250px", justifyContent: "center" }}
          handleClick={handleSaveClick}
          label={isNew ? t("Next") : t("Save")}
          loading={loadingButton}
        />
        {DEMO_VENDORS.includes(vendor) &&
          getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}
      </div>
    </div>
    //      </div>
    //   </div>
    // </div>
  );
};

export default EditProperty;
