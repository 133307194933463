import React, { useState, useEffect } from "react";
import MyListings from "./my-listings";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getUserProile } from "../../../api/userApi";
import { useAuth } from "../../../helpers/context";
import AppLayout from "../../../components/layouts/app-layout";

const MyVIPListing = () => {
  
  const { token } = useAuth();
  const { t, i18n } = useTranslation();
  const [user] = useState({
    userId: token,
    language: i18n.language === 'ar' ? '0' : '1',
  });

  const [userInformation, setuserInformation] = useState();
  const [msg, setMsg] = useState("");

  useEffect(() => {
    if (token)
      getUserProile(user).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          setuserInformation(_userInfo.response.userProfile);
          setMsg("Done");
        } else {
          toast.error(_userInfo.resStr);
          setMsg(_userInfo.resStr);
        }
      });
  }, [token, user]);

  return (
    <AppLayout needAuth pageTitle={t("VIPADs")} withoutNav={true}>
      {
        token && (
          <>
            {msg === "Done" ? (
              <MyListings
                userId={user.userId}
                accountType={userInformation.accountType}
                isAdmin={true}
                userType={userInformation.userType}
              />
            ) : (
              <div className='add-new-property-area pd-top-90 mg-bottom-100 text-center'>
                <h4>{msg}</h4>
              </div>
            )}
          </>
        )
      }
    </AppLayout>
  );
}

export default MyVIPListing;
