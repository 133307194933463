import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function CompanyManagerIDVerficationForm(props) {
  const location = useLocation();
  const { t } = useTranslation();
  const [_CRResponse,set_CRResponse] = useState({ managers: { response: {}, resCode: 1, resStr: "" } });
  const [selectedId, setSelectedId] = React.useState('');

  useEffect(() => {
    if (location?.state && location.state?._CRResponse) {
      set_CRResponse(location?.state?._CRResponse);
      setSelectedId(location?.state?._CRResponse?.managers?.response?.managerInfo?.[0]?.id || "");
      props?.setUser({ ...props.user, managerId: location?.state?._CRResponse?.managers?.response?.managerInfo?.[0]?.id || "" });
    }
  },// eslint-disable-next-line
    [location]);
  
  return (
    <form onSubmit={props.onSubmit}>
      
      {_CRResponse.managers &&
        _CRResponse.managers?.response?.managerInfo?.length > 0 && (
          <>
            <div className='col-12 p-0'>
              <h5 className="mb-3">{t("PleaseEnsureThatYouHaveNationalAccessAccount")}</h5>
            </div>
            <RadioGroup
              name="ids"
              value={selectedId}
              onChange={(event) => {
                setSelectedId(event.target.value);
              }}
            >
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: '100%' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">{t("ID")}</TableCell>
                      <TableCell align="right">{t("ManagerName")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_CRResponse.managers && _CRResponse.managers?.response?.managerInfo?.map((itemlist, i) => (
                      <TableRow
                        key={itemlist.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" align="right" scope="row">
                          <FormControlLabel sx={{ m: 0 }} value={itemlist.id} control={<Radio />} label={itemlist.id} />
                        </TableCell>
                        <TableCell align="right">{itemlist.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </RadioGroup>
          </>
        )}
 
      {/* {_CRResponse.managers &&
            _CRResponse.managers?.response?.managerInfo?.map((itemlist, i) => (
              <div className='row justify-content-md-center mt-3'>
                <div className='col-6 col-lg-2 text-align-last-center'>
                  <h4>{itemlist.id}</h4>
                </div>
                <div className='col-6 col-lg-2 text-align-last-center'>
                  <h4>{itemlist.name}</h4>
                </div>
              </div>
            ))} */}
      <div className='col-12 p-0 mt-4 mb-3'>
        <h5 style={{ color: "red" }}>{t("VerificationWillBeDoneThroughTheNationalAccessCenter")} </h5>
      </div>

      <div className='col-12 p-0'>
        <input
          id='CVnextToOTP'
          type='submit'
          className='primary-btn py-2 px-4 mb-3'
          style={{ width: 'fit-content' }}
          value={t("Next")}
          disable={props.disable}
        />
      </div>
    </form>
  );
}

CompanyManagerIDVerficationForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default CompanyManagerIDVerficationForm;
