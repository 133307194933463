import React, { useState } from "react";
import PropTypes from "prop-types";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  Collapse,
  Grow,
  Hidden,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import data from "../data";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import LogoutIcon from "@mui/icons-material/Logout";
import { useEffect } from "react";
import {
  getListOfContactedPeople,
  getUserProile,
  getWLBasicConfig,
  getWLProfile,
  getWlPermission,
  setLanguageForUser,
} from "../../../api/userApi";
import {
  ChevronRight,
  ContactPhone,
  ContactPhoneOutlined,
  ExpandLess,
  ExpandMore,
  HomeWork,
  HomeWorkOutlined,
  Language,
  MoreHoriz,
  Notifications,
  NotificationsNone,
} from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";
import { useAuth } from "../../../helpers/context";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ChangePasswordModal from "../../global-components/change-password-modal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PlansIcon, ProfileIcon } from "../../../constants/icons";
import { useTranslation } from "react-i18next";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import NotificationsPopper from "./notifications-popper";
import i18next from "i18next";
import Close from "@mui/icons-material/Close";

const drawerWidth = 240;

function LinkTo(props) {
  return (
    <Link
      to={props.linkTo}
      className={`${props.classes || ""} ${
        props.activationLinks?.includes("/")
          ? props.activationLinks?.includes(window.location.pathname) &&
            !props.open
            ? "active-link"
            : ""
          : props.activationLinks.filter((i) =>
              window.location.pathname.includes(i)
            )?.length > 0 && !props.open
          ? "active-link"
          : ""
      } ${props.component ? "default-color" : ""} `}>
      {
        <span>
          {props.activationLinks.filter((i) =>
            window.location.pathname.includes(i)
          )?.length > 0 && !props.open
            ? props.activeIcon
            : props.icon}
        </span>
      }
      <span style={{ marginTop: "-5px" }}>{props.name}</span>
    </Link>
  );
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const Header = (props) => {
  const {
    token,
    setToken,
    setUserId,
    currentPlan,
    setCurrentPlan,
    setAutoTranslate,
    setUnreadMessagesCount,
    unreadMessagesCount,
  } = useAuth();
  const history = useNavigate();
  const location = useLocation();
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  // const [templateID, setTemplateID] = useState('');
  const {
    logo,
    setAccountType,
    AccountType,
    setCurrentDomain,
    vendor,
    UserType,
    setPermissions,
    Permissions
  } = useAuth();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openPopper, setOpenPopper] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [WlUser, setWlUser] = useState({});
  const [openSubMenu, setOpenSubMenu] = useState([
    { key: 444, open: false },
    { key: 999, open: false },
    { key: 222, open: false },
    { key: 333, open: false },
    { key: 100, open: false }
  ]);

  const [openSideMenuPopper, setOpenSideMenuPopper] = useState(false);
  const [popperKey, setPopperKey] = useState();
  // const [openEditProfileModal, setOpenEditProfileModal] = useState(false);
  // const [openSearchPopover, setOpenSearchPopover] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [name, setName] = React.useState("");
  const { t, i18n } = useTranslation();

  const checkMenuActivation = (index, name) => {
    if (
      (index === 4 && name === "website-management") ||
      (index === 5 && name === "website-management") ||
      (index === 6 && name === "website-management") ||
      (index === 8 && name === "website-management") ||
      (index === 11 && name === "website-management") ||
      (index === 444 && name === "website-management") ||
      (index === 999 && name === "customers-management") ||
      (index === 8976 && name === "customers-management") ||
      (index === 8977 && name === "customers-management") ||
      (index === 8978 && name === "customers-management") ||
      (index === 8979 && name === "customers-management") ||
      (index === 222 && name === "ads-management") ||
      (index === 2 && name === "ads-management") ||
      (index === 89710 && name === "ads-management") ||
      (index === 20003 && name === "ads-management") ||
      (index === 2534 && name === "ads-management") ||
      (index === 254 && name === "ads-management") ||
      (index === 7 && name === "website-management") ||
      (index === 59 && name === "website-management") ||
      (index === 9 && name === "msgs-management") ||
      (index === 16 && name === "msgs-management") ||
      (index === 333 && name === "msgs-management") ||
      (index === 101 && name === "agents-management") ||
      (index === 102 && name === "agents-management") ||
      (index === 103 && name === "agents-management") 
    )
      return true;
    return false;
  };

  const buttonProps = (value, subMenu, name) => ({
    selected: subMenu
      ? checkMenuActivation(selectedIndex, name)
      : selectedIndex === value,
    onClick: (e) => {
      if (
        (value === 444 || value === 999 || value === 222 || value === 333 || value === 100) &&
        open
      ) {
        setOpenSubMenu(
          openSubMenu.map((i) => {
            return { key: i.key, open: i.key === value ? !i.open : false };
          })
        );
      } else {
        if (value === 444 || value === 999 || value === 222 || value === 333 || value === 100) {
          setAnchorEl2(e.target);
          setOpenSideMenuPopper(true);
          setPopperKey(value);
          setOpenPopper(false);
          setAnchorEl(null);
        } else {
          setOpenSideMenuPopper(false);
          setPopperKey(0);
          setAnchorEl2(null);
        }
        setSelectedIndex(value);
      }
      if (!isLargeScreen && !subMenu) {
        setOpen(false);
      }
    },
  });

  // useEffect(() => {
  //   if (location.pathname.includes('plans')) {
  //     setSelectedIndex(12);
  //   }
  // }, [location])

  useEffect(
    () => {
      if (vendor && token) {
        getWLBasicConfig({
          userName: vendor,
          userId: token,
          language: i18n.language === "ar" ? "0" : "1",
        }).then((res) => {
          setAccountType(res.response.accountType);
          setCurrentPlan({
            isTrialVersion: res.response.isTrialVersion || "1",
            plan: res.response?.plan?.id
              ? res.response.plan
              : {
                name: "",
                cost: "0",
                id: "",
                duration: "365",
              },
            endDate: res.response.endDate,
            startDate: res.response.startDate,
          });
        });
        getWLProfile({
          userName: vendor,
          language: i18n.language === "ar" ? "0" : "1",
        }).then((_userInfo) => {
          if (_userInfo.resCode === 0) {
            setWlUser(_userInfo.response.wlUser);
            setAutoTranslate(_userInfo.response.wlUser?.autoTranslate);
            setCurrentDomain(
              _userInfo?.response?.wlUser?.domain ||
              _userInfo?.response?.wlUser?.userName + ".amakkn.com"
            );
            // setUserId(_userInfo?.response?.wlUser?.userId);
          }
        });
        getListOfContactedPeople({
          userId: token,
          language: i18n.language === "ar" ? "0" : "1",
        }).then((res) => {
          if (res.resCode === 0) {
            let messages = res.response.contactedList;
            const sum = messages.reduce(
              (accumulator, message) =>
                accumulator + (message.isRead === "0" ? 1 : 0),
              0
            );
            setUnreadMessagesCount(sum);
          }
        });

        if (UserType === "5") {
          getUserProile({
            userId: token,
            language: i18n.language === "ar" ? "0" : "1",
          }).then((_userInfo) => {
            if (_userInfo.resCode === 0) {
              setName(_userInfo.response.userProfile.name);
            }
          });
          getWlPermission({ userId: token }).then(res => {
            if (res.resCode === 0) {
              setPermissions(res.response.permissions);
            }
          });
        }
      }
    }, // eslint-disable-next-line
    [vendor, token, UserType]
  );

  useEffect(
    () => {
      const activePath = location.pathname;
      if (activePath.indexOf("dashboard") > -1) {
        setSelectedIndex(0);
      } else if (activePath.indexOf("project") > -1) {
        setSelectedIndex(1);
      } else if (activePath.indexOf("property/add") > -1) {
        setSelectedIndex(20003);
        setOpenSubMenu([{ key: 222, open: true }, ...openSubMenu]);
      } else if (
        activePath.indexOf("property") > -1 ||
        activePath.indexOf("company-profile") > -1
      ) {
        setSelectedIndex(2);
        setOpenSubMenu([{ key: 222, open: true }, ...openSubMenu]);
      } else if (activePath.indexOf("crm") > -1) {
        setSelectedIndex(3);
      } else if (activePath.indexOf("style") > -1) {
        setSelectedIndex(4);
        setOpenSubMenu([{ key: 444, open: true }, ...openSubMenu]);
      } else if (activePath.indexOf("templates") > -1) {
        setSelectedIndex(5);
        setOpenSubMenu([{ key: 444, open: true }, ...openSubMenu]);
      } else if (activePath.indexOf("sections") > -1) {
        setSelectedIndex(6);
        setOpenSubMenu([{ key: 444, open: true }, ...openSubMenu]);
      } else if (activePath.indexOf("domain") > -1) {
        setSelectedIndex(7);
        setOpenSubMenu([{ key: 444, open: true }, ...openSubMenu]);
      } else if (activePath.indexOf("addons") > -1) {
        if (UserType === "5") {
          setSelectedIndex(60);
        } else {
          setSelectedIndex(59);
          setOpenSubMenu([{ key: 444, open: true }, ...openSubMenu]);
        }
      } else if (activePath.indexOf("about") > -1) {
        setSelectedIndex(8);
        setOpenSubMenu([{ key: 444, open: true }, ...openSubMenu]);
      } else if (activePath.indexOf("agents") > -1 ||activePath.indexOf("user-profile") > -1) {
        setSelectedIndex(101);
        setOpenSubMenu([{ key: 100, open: true }, ...openSubMenu]);
      }else if (activePath.indexOf("add-agent") > -1) {
        setSelectedIndex(102);
        setOpenSubMenu([{ key: 100, open: true }, ...openSubMenu]);
      }else if (activePath.indexOf("manage-permissions") > -1) {
        setSelectedIndex(103);
        setOpenSubMenu([{ key: 100, open: true }, ...openSubMenu]);
      }else if (activePath.indexOf("contact-info") > -1) {
        setSelectedIndex(11);
        setOpenSubMenu([{ key: 444, open: true }, ...openSubMenu]);
      } else if (activePath.indexOf("ejar") > -1) {
        setSelectedIndex(99);
      } else if (activePath.indexOf("profile") > -1) {
        setSelectedIndex(15);
      } else if (activePath.indexOf("chat") > -1) {
        setSelectedIndex(16);
        setOpenSubMenu([{ key: 333, open: true }, ...openSubMenu]);
      } else if (activePath.indexOf("ticket") > -1) {
        setSelectedIndex(17);
      } else if (activePath.indexOf("request-fal") > -1) {
        setSelectedIndex(18);
      } else if (
        activePath.indexOf("blog") > -1 ||
        activePath.indexOf("news") > -1
      ) {
        setSelectedIndex(8956);
      } else if (activePath.indexOf("reports") > -1) {
        setSelectedIndex(174);
      } else if (activePath.indexOf("ads-complaints") > -1) {
        setSelectedIndex(170);
      } else if (activePath.indexOf("matched-customers") > -1) {
        setSelectedIndex(8978);
        setOpenSubMenu([{ key: 999, open: true }, ...openSubMenu]);
      } else if (activePath.indexOf("deals") > -1) {
        setSelectedIndex(8979);
        setOpenSubMenu([{ key: 999, open: true }, ...openSubMenu]);
      } else if (activePath.indexOf("interested") > -1) {
        setSelectedIndex(89710);
        setOpenSubMenu([{ key: 222, open: true }, ...openSubMenu]);
      } else if (
        activePath.indexOf("customers") > -1 ||
        activePath.indexOf("customer-details") > -1 ||
        activePath.indexOf("edit-customer") > -1
      ) {
        setSelectedIndex(8976);
        setOpenSubMenu([{ key: 999, open: true }, ...openSubMenu]);
      } else if (activePath.indexOf("add-customer") > -1) {
        setSelectedIndex(8977);
        setOpenSubMenu([{ key: 999, open: true }, ...openSubMenu]);
      } else if (activePath.indexOf("clients-messages") > -1) {
        setSelectedIndex(9);
        setOpenSubMenu([{ key: 333, open: true }, ...openSubMenu]);
      } else if (activePath.indexOf("task") > -1) {
        setSelectedIndex(8590);
      } else if (
        activePath.indexOf("properties-requests") > -1 ||
        activePath.indexOf("edit-request") > -1
      ) {
        setSelectedIndex(4587);
      } else if (activePath.indexOf("unlicensed-ads") > -1) {
        setSelectedIndex(254);
        setOpenSubMenu([{ key: 222, open: true }, ...openSubMenu]);
      } else if (activePath.indexOf("vip-ads") > -1) {
        setSelectedIndex(2534);
        setOpenSubMenu([{ key: 222, open: true }, ...openSubMenu]);
      } else if (activePath.indexOf("/settings") > -1) {
        setSelectedIndex(14517);
      }
    }, // eslint-disable-next-line
    [location]
  );

  const { open, setOpen } = props;

  const toggleDrawer = () => {
    setOpenPopper(false);
    setOpen(!open);
    setOpenSideMenuPopper(false);
    setPopperKey(0);
    setAnchorEl2(null);
  };

  const ProfilePopper = () => {
    const handleSignOut = () => {
      setOpenPopper(false);
      setToken(undefined);
      setUserId(undefined);
      document.cookie.split(";").forEach(function (c) {
        if (!c.includes("AmmaknConsent"))
          document.cookie = c
            .replace(/^ +/, "")
            .replace(
              /=.*/,
              "=;expires=" + new Date().toUTCString() + ";path=/"
            );
      });
      history("/?signout");
      sessionStorage?.clear?.();
      localStorage?.clear?.();
    };

    return (
      <Popper
        sx={{
          zIndex: 100,
          offset: "55px 0 0 5px",
        }}
        placement='bottom-start'
        open={openPopper}
        role={undefined}
        transition
        anchorEl={anchorEl}
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}>
            <Paper>
              <ClickAwayListener
                onClickAway={() => {
                  setAnchorEl(null);
                  setOpenPopper(false);
                }}>
                <Box sx={{ minWidth: "250px" }}>
                  <Box
                    onClick={() => history("/admin/profile")}
                    className='d-flex flex-column align-items-center justify-content-center'
                    sx={{
                      padding: "15px 10px",
                      gap: "10px",
                      cursor: "pointer",
                    }}>
                    <Avatar
                      variant='circular'
                      sx={{
                        width: 80,
                        height: 80,
                        bgcolor: "var(--main-color-one)",
                      }}
                      className='avatar-contained'
                      alt={"profile image"}
                    />
                    <Box
                      className='d-flex flex-column align-items-center justify-content-center'
                      sx={{ gap: "10px" }}>
                      <h5>
                        {UserType === "5" ? name : WlUser.briefDescription}
                      </h5>
                    </Box>
                  </Box>
                  <Divider variant='inset' className='w-100' />
                  <MenuList autoFocusItem className='list'>
                    {((UserType === "5" && Permissions?.filter(i => i==="MyProfile")?.length > 0) || UserType !== "5") && (
                      <MenuItem
                        className='profile-menu-item'
                        onClick={() => {
                          setOpenPopper(false);
                          history("/admin/profile");
                        }}>
                        {t("MyProfile")}
                        <ProfileIcon />
                      </MenuItem>
                    )}                 
                    {UserType !== "5" && (
                      <MenuItem
                        className='profile-menu-item'
                        onClick={() => {
                          setOpenPopper(false);
                          history("/admin/plans");
                        }}>
                        {t("Subscription")}
                        <PlansIcon />
                      </MenuItem>
                    )}
                    {UserType !== "5" && (
                      <MenuItem
                        className='profile-menu-item'
                        onClick={() => {
                          setOpenChangePasswordModal(true);
                          setOpenPopper(false);
                        }}>
                        {t("SetPassword")}
                        <LockIcon />
                      </MenuItem>
                    )}

                    <MenuItem
                      className='profile-menu-item'
                      onClick={() => {
                        setOpenPopper(false);
                        setAnchorEl(null);
                        if (i18n.language === "ar") {
                          i18next.changeLanguage("en");
                          i18n.language = "en";
                          i18n.options.lng = "en";
                          localStorage.setItem("i18nextLng", "en");
                          document.documentElement.setAttribute("lang", "en");
                        } else {
                          i18next.changeLanguage("ar");
                          i18n.language = "ar";
                          i18n.options.lng = "ar";
                          localStorage.setItem("i18nextLng", "ar");
                          document.documentElement.setAttribute("lang", "ar");
                        }
                        setLanguageForUser({
                          language: i18n.language === "ar" ? "1" : "0",
                          userId: token,
                        });
                      }}>
                      {i18n.language === "ar" ? "English" : "العربيّة"}
                      <Language />
                    </MenuItem>

                    {(currentPlan?.plan?.id === "2" ||
                      currentPlan?.plan?.id === "3") && (
                        <MenuItem
                          className='profile-menu-item'
                          onClick={() => setOpenPopper(false)}>
                          <Link
                            to='/admin/ticket'
                            className='profile-menu-item p-0'>
                            {t("CustomerSupport")}
                            <SupportAgentIcon />
                          </Link>
                        </MenuItem>
                      )}

                    <MenuItem
                      className='profile-menu-item'
                      onClick={handleSignOut}>
                      {t("LogOut")}
                      <LogoutIcon />
                    </MenuItem>
                  </MenuList>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  };

  // const SearchBar = () => {
  //   return (
  //     <div
  //       className='rld-single-input left-icon'
  //       >
  //       <Autocomplete
  //         placeholder={"أدخل العنوان للبحث"}
  //         // onPlaceSelected={(place) => {
  //         //   handleLocationChange(place);
  //         // }}
  //         style={{
  //           fontSize: "15px",
  //         }}
  //         options={{
  //           types: ["(regions)"],
  //           componentRestrictions: { country: "SA" },
  //         }}
  //       />
  //     </div>
  //   );
  // }

  const SideMenuPopper = () => {
    return (
      <Popper
        sx={{
          zIndex: 99999999,
          offset: "0 0 0 55px",
        }}
        open={openSideMenuPopper}
        role={undefined}
        transition
        placement='left'
        anchorEl={anchorEl2}
      // disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}>
            <Paper>
              <ClickAwayListener
                onClickAway={() => {
                  setAnchorEl2(null);
                  setOpenSideMenuPopper(false);
                  setPopperKey(0);
                }}>
                <List component='div' disablePadding>
                  {data.menus
                    .filter(
                      (i) =>
                        i.subMenu ===
                        (popperKey === 999
                          ? "customers-management"
                          : popperKey === 222
                            ? "ads-management"
                            : popperKey === 333
                              ? "msgs-management" :
                                popperKey === 100 ?
                                  "agents-management"
                              : "website-management")
                    )
                    .map((subItem, i) => renderListItem(subItem, i, true))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  };

  const renderListItem = (Item, index, subMenu = false) => {
    const button = (
      <ListItemButton
        key={index + Item.text}
        sx={{ paddingInlineStart: subMenu ? 4 : "" }}
        {...buttonProps(Item.index, false, Item.subMenu)}
        className={`${open
          ? "sidebar-item"
          : !subMenu
            ? "sidebar-item"
            : `profile-menu-item dark`
          }`}>
        <ListItemIcon className='sidebar-icon'>{Item.icon}</ListItemIcon>
        <ListItemText sx={{ display: "flex" }} primary={t(Item.text)} />
        {Item.showBadge && (
          <Badge
            sx={{
              display: "flex",
              "& .MuiBadge-badge": {
                top: "5%",
                right: "unset",
                position: "relative",
                left: "12px",
                zIndex: 99,
                transform: "translateX(-50%)",
              },
            }}
            color='primary'
            badgeContent={unreadMessagesCount}
            showZero
            max={999}></Badge>
        )}
      </ListItemButton>
    );
    return (
      !Item.hidden &&
      (Item.accountType === undefined ||
        Item.accountType?.includes(AccountType)) &&
      ((UserType === "5" && Item?.permissions?.length > 0 && Permissions?.filter(y => Item.permissions.includes(y))?.length > 0) || UserType !== "5") && (
        <Link key={index + Item.text} to={Item.link}>
          {!open && !subMenu ? (
            <Tooltip title={t(Item.text)} placement='left'>
              {button}
            </Tooltip>
          ) : (
            button
          )}
        </Link>
      )
    );
  };

  return (
    <>
      <AppBar open={open} className='main-header'>
        <Toolbar className='main-toolbar'>
          {!open && (
            <>
              {isLargeScreen && (
                <IconButton
                  edge='start'
                  color='inherit'
                  aria-label='open drawer'
                  className='mobile-version'
                  onClick={toggleDrawer}
                  sx={{
                    color: "#121212",
                    background: "none",
                    "&:hover": {
                      color: "var(--main-color-one)",
                      background: "none",
                    },
                    marginRight: "0px",
                    borderRadius: "8px",
                    marginLeft: "4px",
                    width: "33px",
                    height: "33px",
                  }}>
                  <MenuOpenIcon
                    sx={
                      i18n.language !== "ar"
                        ? { transform: "rotate(180deg)" }
                        : {}
                    }
                  />
                </IconButton>
              )}
              <Tooltip title={t("ExpandList")}>
                <IconButton
                  edge='start'
                  color='inherit'
                  aria-label='open drawer'
                  className='desktop-version'
                  onClick={toggleDrawer}
                  sx={{
                    color: "#121212",
                    background: "none",
                    "&:hover": {
                      color: "var(--main-color-one)",
                      background: "none",
                    },
                    marginRight: "0px",
                    borderRadius: "8px",
                    marginLeft: "4px",
                    width: "33px",
                    height: "33px",
                  }}>
                  <MenuOpenIcon
                    sx={
                      i18n.language !== "ar"
                        ? { transform: "rotate(180deg)" }
                        : {}
                    }
                  />
                </IconButton>
              </Tooltip>
            </>
          )}

          <Tooltip title={t("GoBack")}>
            <IconButton
              edge='start'
              color='inherit'
              aria-label='open drawer'
              onClick={() => history(-1)}
              sx={{
                color: "#121212",
                background: "none",
                "&:hover": {
                  color: "var(--main-color-one)",
                  background: "none",
                },
                marginRight: "0px",
                borderRadius: "8px",
                width: "33px",
                height: "33px",
              }}>
              <ChevronRight
                sx={
                  i18n.language !== "ar" ? { transform: "rotate(180deg)" } : {}
                }
              />
            </IconButton>
          </Tooltip>

          <div className='page-info-wrapper'>
            <h5>
              {t(
                data?.menus?.filter((i) =>
                  window.location.pathname.includes(i.link)
                )?.[0]?.text
              )}
            </h5>
            <Hidden mdDown>
              {data?.menus?.filter((i) =>
                window.location.pathname.includes(i.link)
              )?.[0]?.description && (
                  <span>
                    {t(
                      data?.menus?.filter((i) =>
                        window.location.pathname.includes(i.link)
                      )?.[0]?.description
                    )}
                  </span>
                )}
            </Hidden>
          </div>

          {/* <div className="search-bar mr-3 ml-2 desktop-version">
            <SearchBar />
          </div> */}
          {/* <h4
            style={{
              marginRight: "10px",
              marginTop: "10px",
              color: "white",
            }}>
            لوحة التحكم
          </h4> */}

          {/* <div className={classes.grow} />
          <div className={classes.sectionDesktop}> */}
          {/* <IconButton color='inherit'>
                <Badge
                  className={classes.margin}
                  badgeContent={4}
                  color='secondary'>
                  <MailIcon />
                </Badge>
              </IconButton>
              <IconButton color='inherit'>
                <Badge
                  className={classes.margin}
                  badgeContent={17}
                  color='secondary'>
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton
                aria-owns={isMenuOpen ? "material-appbar" : null}
                aria-haspopup='true'
                onClick={this.handleProfileMenuOpen}
                color='inherit'>
                <AccountCircle />
              </IconButton> */}
          <div className='d-flex' style={{ gap: "10px" }}>
            {/* <Tooltip title="البحث">
              <IconButton
                edge='start'
                className="mobile-version"
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                  setOpenPopper(false);
                  setOpenSearchPopover(true);
                }}
                sx={{
                  color: '#121212',
                  '&:hover': { color: 'var(--main-color-one)' },
                  marginRight: "0px",
                }}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
            <Popover
              open={openSearchPopover}
              anchorEl={anchorEl}
              onClose={() => { setOpenSearchPopover(false); setAnchorEl(null); }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <SearchBar />
            </Popover> */}
            <Tooltip title={t("GoToHomePage")}>
              <IconButton
                edge='start'
                onClick={() => window.open("/", "_blank")}
                sx={{
                  color: "#121212",
                  background: "none",
                  "&:hover": {
                    color: "var(--main-color-one)",
                    background: "none",
                  },
                  marginRight: "0px",
                  borderRadius: "8px",
                  width: "33px",
                  height: "33px",
                  top: 7,
                }}>
                <OpenInNewIcon sx={{ width: 24, height: 24 }} />
              </IconButton>
            </Tooltip>
            <NotificationsPopper />
            <Tooltip title={t("MyProfile")}>
              <IconButton
                edge='start'
                // onMouseEnter={(e) => { setOpenPopper(true);    setAnchorEl(anchorEl ? null : e.target);}}
                onClick={(e) => {
                  setOpenPopper(!openPopper);
                  setAnchorEl(anchorEl ? null : e.target);
                  setOpenSideMenuPopper(false);
                  setAnchorEl2(null);
                }}
                sx={{
                  marginRight: "0px",
                }}>
                <Avatar
                  variant='circular'
                  sx={{
                    color: "#fff",
                    background: "var(--main-color-one)",
                    "&:hover": { color: "#fff", background: "none" },
                    marginRight: "0px",
                    width: "32px",
                    borderRadius: "inherit",

                    height: "32px",
                  }}
                  className='avatar-contained'
                  alt={"profile image"}
                />
                <ExpandMoreIcon sx={{ color: "#121212" }} />
              </IconButton>
            </Tooltip>
            <ProfilePopper />
            {/* <ChangePasswordModal /> */}
            {/* {open && <span>
              {props.userProfile?.name && props.userProfile?.name.length > 22 ? props.userProfile?.name.substr(0, 22) + '..' : props.userProfile?.name}
            </span>}
          */}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        className={`main-drawer ${open ? "opened" : ""}`}
        open={open}
        sx={{ textAlignLast: "right" }}
        PaperProps={{
          sx: {
            backgroundColor: "#121212",
            color: "#fff",
            borderRadius: "0",
            boxShadow: "none",
          },
        }}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
            height: "50px",
          }}>
          <img
            src={logo}
            alt='amakkn-logo'
            style={{ margin: "auto", maxHeight: 50, maxWidth: "185px" }}
          />
          <Tooltip title={open ? t("CollapseList") : t("ExpandList")}>
            <IconButton
              edge='start'
              color='inherit'
              aria-label='open drawer'
              onClick={toggleDrawer}
              sx={{
                color: "#fff",
                background: "none",
                "&:hover": {
                  color: "var(--main-color-one)",
                  background: "none",
                },
                marginInlineEnd: "3px",
                borderRadius: "8px",
                width: "33px",
                height: "33px",
                position: isLargeScreen ? "relative" : "absolute",
              }}>
              {isLargeScreen ? (
                <MenuOpenIcon
                  sx={
                    i18n.language === "ar"
                      ? { transform: "rotate(180deg)" }
                      : {}
                  }
                />
              ) : (
                <Close />
              )}
            </IconButton>
          </Tooltip>
        </Toolbar>
        <Divider />
        <List className='sidebar-list'>
          {AccountType &&
            data.menus.map(
              (Item, index) =>
                !Item.hidden &&
                (!Item.base && !Item.subMenu
                  ? (Item.userType?.includes(UserType) && UserType === "5" ?
                    (Permissions?.filter(i => Item.permissions.includes(i))?.length > 0 ? renderListItem(Item, index) : null)
                    : renderListItem(Item, index))
                  : Item.base &&
                  Item.userType?.includes(UserType) && (
                    <div key={index + Item.text}>
                      {!open ? ((UserType === "5" && Permissions?.filter(i => Item.permissions.includes(i))?.length > 0) || UserType !== "5") && (
                        <Tooltip title={Item.text} placement='left'>
                          <ListItemButton
                            {...buttonProps(Item.index, true, Item.name)}
                            className='sidebar-item'>
                            <ListItemIcon>
                              <ListItemIcon className='sidebar-icon'>
                                {Item.icon}
                              </ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary={t(Item.text)} />
                          </ListItemButton>
                        </Tooltip>
                      ) : ((UserType === "5" && Permissions?.filter(i => Item.permissions.includes(i))?.length > 0) || UserType !== "5") &&
                      (
                        <>
                          <ListItemButton
                            {...buttonProps(Item.index, true, Item.name)}
                            className='sidebar-item'>
                            <ListItemIcon>
                              <ListItemIcon className='sidebar-icon'>
                                {Item.icon}
                              </ListItemIcon>
                            </ListItemIcon>
                            <ListItemText
                              sx={{ display: "flex" }}
                              primary={t(Item.text)}
                            />
                            {openSubMenu.filter(
                              (i) => i.key === Item.index
                            )?.[0]?.open ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItemButton>
                          <Collapse
                            in={
                              openSubMenu.filter(
                                (i) => i.key === Item.index
                              )?.[0]?.open
                            }
                            timeout='auto'
                            unmountOnExit>
                            <List component='div' disablePadding>
                              {data.menus
                                .filter((i) => i.subMenu === Item.name)
                                .map((subItem, i) => ((UserType === "5" && subItem?.permissions?.length > 0 && Permissions?.filter(y => subItem.permissions.includes(y))?.length > 0) || UserType !== "5") ?
                                  renderListItem(subItem, i, true) : null
                                )}
                            </List>
                          </Collapse>
                        </>
                      )
                      }
                    </div>
                  ))
            )}
        </List>
        {isLargeScreen && (
          <div className={`wl-version ${open ? "" : "collapsed"}`}>
            <div>
              <img
                src={`/assets/img/logo/logo.svg`}
                alt='amakkn-logo'
                style={{ margin: "auto", width: 33 }}
              />
            </div>
            {open && (
              <div>
                <h6
                  className='mt-0'
                  style={{
                    fontWeight: 600,
                    marginBottom: "2px",
                    fontSize: "15px",
                  }}>
                  {currentPlan?.plan?.name || "أماكن برو"}
                </h6>
                <p className='m-0' style={{ fontSize: "11px" }}>
                  {t("WLVersion", { number: "2.1.0" })}
                </p>
              </div>
            )}
          </div>
        )}
      </Drawer>
      {!isLargeScreen && (
        <nav className='bottom-navigation admin'>
          <ul className='list'>
            <li style={{ flex: 1 }}>
              <div
                className={`link ${open ? "active-link" : ""}`}
                onClick={() => setOpen(!open)}>
                <span>
                  <MoreHoriz sx={open ? { fontSize: "30px" } : { fontSize: "30px", fill: '#FFF', stroke: '#616161' }} />
                </span>
                <span style={{ marginTop: "-10px" }}>{t("More")}</span>
              </div>
            </li>
            {((UserType === "5" && Permissions?.filter(y => y === "RealEstateADs")?.length > 0) || UserType !== "5") &&
              (
                <li onClick={() => setOpen(false)} style={{ flex: 1 }}>
                  <LinkTo
                    linkTo='/admin/property/my-listings'
                    activationLinks={["/admin/property/my-listings"]}
                    icon={<HomeWorkOutlined />}
                    activeIcon={<HomeWork />}
                    component
                    open={open}
                    classes='link'
                    name={t("RealEstateADs")}
                  />
                </li>
              )}
            {((UserType === "5" && Permissions?.filter(y => y === "CustomersList")?.length > 0) || UserType !== "5") &&
              (
                <li onClick={() => setOpen(false)} style={{ flex: 1 }}>
                  <LinkTo
                    linkTo='/admin/customers'
                    activationLinks={["/admin/customers"]}
                    icon={<ContactPhoneOutlined />}
                    activeIcon={<ContactPhone />}
                    component
                    open={open}
                    classes='link'
                    name={t("CustomersList")}
                  />
                </li>
              )}
            <li onClick={() => setOpen(false)} style={{ flex: 1 }}>
              <LinkTo
                linkTo='/admin/notifications'
                activationLinks={["/admin/notifications"]}
                icon={<NotificationsNone sx={{ fontSize: "35px" }} />}
                activeIcon={<Notifications sx={{ fontSize: "35px" }} />}
                component
                open={open}
                classes='link'
                name={t("Notifications")}
              />
            </li>
          </ul>
        </nav>
      )}

      {!open && <SideMenuPopper />}
      <ChangePasswordModal
        openChangePasswordModal={openChangePasswordModal}
        setOpenChangePasswordModal={setOpenChangePasswordModal}
        agentId={token}
        agent={UserType === "5"}
      />
    </>
  );
}

Header.propTypes = {
  // styles: PropTypes.object,
  handleChangeNavDrawer: PropTypes.func,
  classes: PropTypes.object,
  navDrawerOpen: PropTypes.bool,
};

// export default withStyles(styles)(Header);
export default Header;
