import React, { useState, useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { getMenuItems, getWLBasicConfig, saveMenuItems } from "../../../../api/userApi";
import { toast } from "react-toastify";
import { useAuth } from "../../../../helpers/context";
import { Alert, MenuItem, TextField } from "@mui/material";
import { WLAdministration, WLBlog, WLCall, WLInfo, WLProjects, WLProperties } from "../../../../constants/icons";
import { DEMO_VENDORS } from "../../../../constants";
import { useTranslation } from "react-i18next";

const MenuSection = () => {
  const [MenuItems, setMenuItems] = useState([]);
  const [showPhone, setShowPhone] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [templateID, setTemplateID] = useState('');
  const { token, vendor, setMenuData, setShowMenuPhone, iconPackage, setIconPackage } = useAuth();
  const { t, i18n } = useTranslation();

  const getData = () => {
    if (DEMO_VENDORS.includes(vendor) && localStorage.getItem(`menu-${vendor}`)) {
      const menuStorage = JSON.parse(localStorage.getItem(`menu-${vendor}`));
      if (menuStorage?.templateID)
        setTemplateID(menuStorage?.templateID);
      setMenuItems(menuStorage?.menuData || []);
      setMenuData(menuStorage?.menuData || []);
      setShowPhone(menuStorage?.showPhone === '1');
      setShowMenuPhone(menuStorage?.showPhone === '1');
      setIconPackage(menuStorage?.iconPackage || '1');
    } else {
      getMenuItems({
        userName: vendor,
        language: i18n.language === 'ar' ? '0' : '1',
      }).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          getWLBasicConfig({ userName: vendor, userId: token, language: i18n.language === 'ar' ? '0' : '1' }).then(res => {
            setTemplateID(res.response.templateId.toString());
            setMenuItems(_userInfo.response.menuItems);
            setMenuData(_userInfo.response.menuItems);
            setShowPhone(_userInfo.response.showPhone === '1');
            setShowMenuPhone(_userInfo.response.showPhone === '1');
            setIconPackage(_userInfo.response.iconsPackage || '1');

            if (DEMO_VENDORS.includes(vendor)) {
              localStorage?.setItem(`menu-${vendor}`, JSON.stringify({
                templateID: res.response.templateId.toString(),
                showPhone: _userInfo.response.showPhone,
                menuData: _userInfo.response.menuItems,
                menuItems: res.response.menuItems,
                iconPackage: _userInfo.response.iconsPackage || '1'
              }));
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    if (vendor)
      getData();
  },// eslint-disable-next-line
    [vendor, i18n.language]);

  function handleChange(key) {
    let menuItems = [];
    MenuItems.forEach((element) => {
      if (element.key !== key) menuItems.push({ ...element });
      else
        menuItems.push({
          ...element,
          isVisible: element.isVisible === "0" ? "1" : "0",
        });
    });
    setMenuItems(menuItems);
    onSave(menuItems, showPhone);
  }
  function handleChangePhone() {
    setShowPhone(!showPhone);
    onSave(MenuItems, !showPhone);
  }

  function onSave(menuItems, showPhone, iconPack) {
    let Items = "";
    menuItems.forEach((element) => {
      if (element.isVisible === '0' && element.key === '5') setShowAlert(true);
      else if (element.isVisible === '1' && element.key === '5') setShowAlert(false);
      if (element.isVisible === "1") Items = Items + element.key + ",";
    });
    if (Items.length > 1) Items = Items.slice(0, -1);

    if (DEMO_VENDORS.includes(vendor)) {
      const menuStorage = JSON.parse(localStorage.getItem(`menu-${vendor}`));

      localStorage?.setItem(`menu-${vendor}`, JSON.stringify({
        templateID: menuStorage.templateID,
        showPhone: showPhone ? '1' : '0',
        menuData: menuItems.filter(i => i.isVisible === "1"),
        MenuItems: Items,
        iconPackage: iconPack || iconPackage
      }));
    } else {
      saveMenuItems({
        userName: vendor,
        language: i18n.language === 'ar' ? '0' : '1',
        menuItems: Items,
        showPhone: showPhone,
        iconsPackage: iconPack || iconPackage
      }).then((resp) => {
        if (resp.resCode === 0) {
          // toast.success("تم التعديل بنجاح");
          getData();
        } else {
          toast.error(resp.resStr);
        }
      });
    }
    
  }

  return (
    <>
      <div className='custom-card logo menus with-switcher'>
        <div className="card-header">
          <div>
            <h4>{t("MainMenu")}</h4>
            <h6>{t("YouCanControlTheItemsThatAppearInTheMainMenu")}</h6>
          </div>
          {/* {!DARK_TEMPLATES.includes(+templateID) ? ( */}
            <div className="mt-3 mt-lg-0">
              <TextField
                InputLabelProps={{ shrink: true }}
                select
                value={iconPackage}
                sx={{ width: 170 }}
                size="small"
                onChange={(event) => {
                  setIconPackage(event.target.value);
                  onSave(MenuItems, showPhone, event.target.value);
                }
                }
                label={t("IconPack")}
              >
                <MenuItem value={"1"} key={"1"}>{t("Package", { i: 1 })}</MenuItem>
                <MenuItem value={"2"} key={"2"}>{t("Package", { i: 2 })}</MenuItem>
                <MenuItem value={"3"} key={"3"}>{t("Package", { i: 3 })}</MenuItem>
              </TextField>
            </div>
          {/* ) : null} */}
          
        </div>
        <div className="card-body">
          <div className="row w-100">
            <div className="d-flex col-12 justify-content-between flex-column flex-sm-row">
              <FormGroup row className="menus-form-group">
                {MenuItems.map((Item, index) => (Item.key === "1" && (templateID === "1" || templateID === '3')) || Item.key === "7" ? <></> :
                  (
                    <FormControlLabel
                      sx={{
                        borderLeft: '1px solid rgba(0, 0, 0, 0.08)',
                        padding: '0 0 0 16px',
                        '&:last-of-type': { border: 0 },
                        margin: 0
                      }}
                      key={index}
                      control={
                        <Checkbox
                          checked={Item.isVisible === "1"}
                          onChange={() => handleChange(Item.key)}
                        />
                      }
                      label={<div className="d-flex align-items-center" style={{ gap: 5 }}>
                        {/* {!DARK_TEMPLATES.includes(+templateID) ? */}
                        {Item.key === "1" ? <WLProjects packageId={iconPackage} /> :
                          Item.key === "2" ? <WLProperties packageId={iconPackage} /> :
                            Item.key === "3" ? <WLInfo packageId={iconPackage} /> :
                              Item.key === "4" ? <WLCall packageId={iconPackage} /> :
                                Item.key === "5" ? <WLAdministration packageId={iconPackage} /> :
                                  <WLBlog packageId={iconPackage} />
                        }
                        {Item.name}
                      </div>}
                    />
                  ))}
        
              </FormGroup>
              <FormControlLabel
                sx={{
                  padding: 0,
                  margin: 0,
                  display: 'flex',
                  alignItems:'center'
                }}
                key={'tel1'}
                control={
                  <Checkbox
                    checked={showPhone}
                    onChange={() => handleChangePhone()}
                  />
                }
                label={<>
                  {t("ShowPhoneNumber")} &nbsp;
                </>} />
            </div>
            {showAlert && <Alert className="mt-3 mx-3 custom-alert" severity="info">
              {t("YouCanAccessTheAdministrationByGoingToTheFollowingLink")} &nbsp;
              {window.location.origin}/admin
            </Alert>}
          </div>
        </div>
      </div>
    
    </>
  );
}

export default MenuSection;
