import { ChevronLeftOutlined } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getLocalizedText } from "../../../helpers";

const T05AboutUS = ({ type, data }) => {
    const { t, i18n } = useTranslation();

    return (
        <div className="row about-us-card" data-aos='fade-up' data-aos-delay="2000">
            <div className={`col-12 flex-column d-flex align-items-center`}>
                {type !== 'broker' && (<>
                    <span className="section-prefix tag">{t("AboutUS1")}</span>
                    <h2 className='sec-title'>{getLocalizedText(data.title1, i18n.language, false)}</h2>
                </>)}
                <p className="sec-desc col-lg-9 text-center">{data.content}</p>
                <Link style={{ marginTop: '20px', maxWidth: 'fit-content' }} className='py-2 px-4 primary-btn no-border-radius' to={type !== 'broker' ? "About" : 'ejar'}>
                    {type !== 'broker' ? t("ReadTheFullStory") : t("GoToTheRentalAgreement")}
                    <ChevronLeftOutlined/>
                </Link>
            </div>
        </div>
    );
}

export default T05AboutUS;
