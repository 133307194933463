import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  Box,
  FormControl,
  FormHelperText,
  Alert,
  Autocomplete,
  InputAdornment,
  OutlinedInput,
  MenuItem,
  Select,
  InputLabel
} from "@mui/material";
import { toast } from "react-toastify";
import { getRequest,  editRequest, rejectRequest, acceptRequest } from "../../../api/userApi";
import { useEffect } from "react";
import SubHeader from "../../../components/global-components/sub-header";
import LoadingButton from "../../../components/global-components/loading-btn";
import { useAuth } from "../../../helpers/context";
import { openDeleteModal, preventString, resolvePersianAndArabicNumbers, validatePhone } from "../../../helpers";
import { CITIES, DISTRICTS, REGIONS } from "../../../constants";
import { useTranslation } from "react-i18next";

const EditPropertyRequest = () => {
  const { token, vendor } = useAuth();
  const { id } = useParams();
  const [data, setData] = useState({
    propertyType: '',
    name: '',
    phone: '',
    desire: "استئجار",
    neighbourhoodIds: [],
    priceFrom: '',
    priceTo: '',
    areaFrom: '',
    areaTo: '',
    area: '',
    city: '',
    notes: '',
    seriousness:''
  });
  const [loadingButton, setLoadingButton] = useState(false);
  const [errors, setErrors] = React.useState({});
  const history = useNavigate();
  const { t, i18n } = useTranslation();
  const [isSubmittingReject, setIsSubmittingReject] = useState(false);

  const propertiesOptions = [
    {
      id: 'أرض',
      label: t("Land"),
    },
    {
      id: 'دور',
      label: t("Floor"),
    },
    {
      id: 'شقة',
      label: t("Apartment"),
    },
    {
      id: 'فيلا',
      label: t("Villa"),
    },
    {
      id: 'استوديو',
      label: t("Studio"),
    },
    {
      id: 'غرفة',
      label: t("Room"),
    },
    {
      id: 'استراحة',
      label: t("Resort"),
    },
    {
      id: 'معرض',
      label: t("Showroom"),
    },
    {
      id: 'مكتب',
      label: t("Office"),
    },
    {
      id: 'مستودع',
      label: t("Warehouse"),
    },
    {
      id: 'مزرعة',
      label: t("Farm"),
    },
    {
      id: 'عمارة',
      label: t("Building"),
    }
  ];
  const regionsOptions = useMemo(
    () => {
      return REGIONS.map((region) => ({
        id: +region.REGION_ID,
        label: i18n.language === "ar" ? region.REGIONNAME_AR : region.REGIONNAME_EN,
      }));
    }, // eslint-disable-next-line
    [i18n.language]);
  const citiesOptions = useMemo(
    () => {
      return CITIES.map((city) => ({
        id: +city.CITY_ID,
        label: i18n.language === "ar" ? city.CITYNAME_AR : city.CITYNAME_EN,
        regionId: +city.REGION_ID,
      }))
    },// eslint-disable-next-line
    [i18n.language]);

  const districtsOptions = useMemo(
    () => {
      return DISTRICTS.map((district) => ({
        id: +district.DISTRICT_ID,
        label: i18n.language === "ar" ? district.DISTRICTNAME_AR : district.DISTRICTNAME_EN,
        regionId: +district.REGION_ID,
        cityId: +district.CITY_ID,
      }));
    }, // eslint-disable-next-line
    [i18n.language]);
  
  function getFormatedPrice(price) {
    let value = price?.toString()?.replace(/[,]+/g, "");
    return value?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  }
  
  useEffect(
    () => {
      if (id)
        getData();
      else
        setData({
          propertyType: '',
          name: '',
          phone: '',
          desire: "استئجار",
          neighbourhoodIds: [],
          priceFrom: '',
          priceTo: '',
          areaFrom: '',
          areaTo: '',
          area: '',
          city: '',
          notes: '',
          seriousness:''
        });
    }, // eslint-disable-next-line
    [id, token]
  );


  const getData = () => {
    getRequest({
      vendorName: vendor,
      language: i18n.language === 'ar' ? '0' : '1',
      requestId: id,
      userId: token
    }).then((res) => {
      if (res.resCode === 0) {
        setData({ ...res.response, desire: res.response.interestingType, neighbourhoodIds: res.response.neighbourhood });
      } else {
        toast.error(res.resStr);
      }
    });
  }

  const handleApprove = (id) => {
    acceptRequest({
      "userId": token,
      "language": i18n.language === 'ar' ? '0' : '1',
      "requestId": id
    }).then((res) => {
      if (res.resCode === 0) {
        toast.success(t("RequestHasBeenAcceptedSuccessfully"));
        if (res.response.customer) {
          history(`/admin/customer-details/${res.response.customer}`, { replace: true });
        }
      } else {
        toast.error(res.resStr);
      }
    });
  }

  const handleReject = (id) => {
    setIsSubmittingReject(true);
    rejectRequest({
      "userId": token,
      "language": i18n.language === 'ar' ? '0' : '1',
      "requestId": id
    }).then((res) => {
      if (res.resCode === 0) {
        toast.success(t("RequestHasBeenRejectedSuccessfully"));
        history('/admin/properties-requests');
      } else {
        toast.error(res.resStr);
      }
    }).finally(()=>setIsSubmittingReject(false));
  }


  function formIsValid() {
    const _errors = {};
    if (!data.name)
      _errors.name = t("EnterName");
  
    if (!data.phone) _errors.phone = t("EnterPhoneNumber");
    else if (!validatePhone(data.phone))
      _errors.phone = t("PhoneNumberIsIncorrect");

    if (!data.desire || !data.propertyType) _errors.desire = t("ChooseYourDesire");
  
    
    if (data.area === '') {
      _errors.area = t("PleaseSelectTheRegion");
    }
    if (data.city === '') {
      _errors.city = t("PleaseSelectTheCity");
    }
    if (data.neighbourhoodIds?.length === 0) {
      _errors.neighbourhoodIds = t("PleaseSelectTheNeighborhoods");
    }
    if (data.priceTo !== "" && data.priceFrom !== "") {
      if (+data.priceFrom?.toString().replace(/[,]+/g, "") > +data.priceTo?.toString().replace(/[,]+/g, ""))
        _errors.price = t("PleaseCheckPrices");
    } else {
      _errors.price = t("PleaseSpecifyPrices");
    }

    if (data.areaFrom !== "" && data.areaTo !== "") {
      if (+data.areaFrom?.toString().replace(/[,]+/g, "") > +data.areaTo?.toString().replace(/[,]+/g, ""))
        _errors.areas = t("PleaseCheckArea");
    } else {
      _errors.areas = t("PleaseSpecifyArea");
    }
     
    if (!data.seriousness) {
      _errors.seriousness = t("PleaseEnterCustomerSeriousness");
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }
  
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      formIsValid();
    }
  }, // eslint-disable-next-line
    [i18n.language]);

  const handleSave = () => {
    if (!formIsValid()) return;
   
    setLoadingButton(true);
    editRequest({
      language: i18n.language === "ar" ? "0" : "1",
      vendorName: vendor,
      interestingType: data.desire,
      priceFrom: data?.priceFrom?.toString().replace(/[,]+/g, ""),
      priceTo: data?.priceTo?.toString().replace(/[,]+/g, ""),
      areaFrom: data?.areaFrom?.toString().replace(/[,]+/g, ""),
      areaTo: data?.areaTo?.toString().replace(/[,]+/g, ""),
      userId: token,
      requestId: id,
      propertyType: data.propertyType,
      name: data.name,
      phone: data.phone,
      neighbourhoodIds: data.neighbourhoodIds,
      area: data.area,
      city: data.city,
      notes: data.notes,
      seriousness: data.seriousness
    }).then(res => {
      if (res.resCode === 0) {
        handleApprove(id);
      } else {
        toast.error(res.resStr);
      }
      
    }).finally(() => setLoadingButton(false));
    
  }

  return (
    <div>
      <SubHeader OKElement={<></>} />
    
      <div className='custom-card' style={{ minHeight: 'auto' }}>
        <div className='card-header'>
          <h4>{t("RequestInformation")}</h4>
          <h6>{t("YouCanEditAllInformationAboutRequest")}</h6>
        </div>
        <div className='card-body'  >
          <div className='row'>
        

            <div className={'col-12 col-md-6 col-lg-4 mb-4'}>
              <FormControl
                error={Boolean(errors.desire)}
                className="col-12"
              >
                <InputLabel htmlFor='Desire' required>{t("Desire")}</InputLabel>

                <Select
                  label={t("Desire")}
                  sx={{ width: "100%" }}
                  type='text'
                  required
                  variant={'outlined'}
                  size='small'
                  onChange={(e) => {
                    setData({ ...data, desire: e.target.value });
                  }}
                  input={<OutlinedInput
                    notched
                    label={t("Desire")}
                    id="Desire"
                  />}
                  inputProps={{
                    name: "Desire",
                    id: "Desire",
                  }}
                  error={Boolean(errors.desire)}
                  value={data.desire}
                >
                  <MenuItem value="استئجار">{t("Rent")} </MenuItem>
                  <MenuItem value="شراء">{t("Buy")} </MenuItem>
                </Select>
              </FormControl>
              
          
            </div>

            <div className={'col-12 col-md-6 col-lg-4 mb-4'}>
              <FormControl
                error={Boolean(errors.desire)}
                className="col-12"
              >
                <InputLabel htmlFor='type' required>{t("PropertyType")}</InputLabel>

                <Select
                  label={t("PropertyType")}
                  sx={{ width: "100%" }}
                  type='text'
                  required
                  variant={'outlined'}
                  size='small'
                  onChange={(e) => {
                    setData({ ...data, propertyType: e.target.value });
                  }}
                  input={<OutlinedInput
                    notched
                    label={t("PropertyType")}
                    id="type"
                  />}
                  inputProps={{
                    name: "type",
                    id: "type",
                  }}
                  error={Boolean(errors.desire)}
                  value={data.propertyType}
                >
                  {propertiesOptions.map((item) => (
                    <MenuItem value={item.id}>{item.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.desire &&
                <FormHelperText component={Box}>
                  <Alert severity="error" className="custom-alert">
                    {errors.desire}
                  </Alert>
                </FormHelperText>}
            </div>

            <div className={'col-12 col-md-6 col-lg-4 mb-4'}>
              <FormControl
                error={Boolean(errors.name)}
                className="col-12"
              >
                <TextField
                  label={t("Name")}
                  sx={{ width: "100%" }}
                  type='text'
                  inputProps={{ maxLength: 50 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoComplete="off"
                  required
                  variant={'outlined'}
                  size='small'
                  onChange={(e) => {
                    setData({ ...data, name: resolvePersianAndArabicNumbers(e.currentTarget.value) });
                  }}
                  error={Boolean(errors.name)}
                  value={data.name}
                />
                {errors.name &&
                  <FormHelperText component={Box}>
                    <Alert severity="error" className="custom-alert">
                      {errors.name}
                    </Alert>
                  </FormHelperText>}
              </FormControl>
            </div>

            <div className={'col-12 col-md-6 col-lg-4 mb-4'}>
              <FormControl
                error={Boolean(errors.phone)}
                className="col-12"
              >
                <TextField
                  label={t("PhoneNumber")}
                  sx={{ width: "100%" }}
                  type='text'
                  inputProps={{ maxLength: 10 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoComplete="off"
                  required
                  variant={'outlined'}
                  size='small'
                  onChange={(e) => {
                    setData({ ...data, phone: preventString(e.currentTarget.value) });
                  }}
                  error={Boolean(errors.phone)}
                  value={data.phone}
                />
                {errors.phone &&
                  <FormHelperText component={Box}>
                    <Alert severity="error" className="custom-alert">
                      {errors.phone}
                    </Alert>
                  </FormHelperText>}
              </FormControl>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <>
                <TextField
                  label={t("AreaFrom")}
                  sx={{ width: "50%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  error={Boolean(errors.areas)}
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: (
                      <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>{t("M2")}</InputAdornment>
                    ),
                  }}
                  value={getFormatedPrice((data.areaFrom))}
                  size='small'
                  onChange={(v) => {
                    setData({ ...data, areaFrom: getFormatedPrice(Math.abs(preventString(v.currentTarget.value?.replace(/[,]+/g, "")))) });
                  }}
                />
                <TextField
                  label={t("AreaTo")}
                  sx={{ width: "50%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  error={Boolean(errors.areas)}
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: (
                      <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>{t("M2")}</InputAdornment>
                    ),
                  }}
                  value={getFormatedPrice(data.areaTo)}
                  size='small'
                  onChange={(v) => {
                    setData({
                      ...data,
                      areaTo: getFormatedPrice(Math.abs(preventString(v.currentTarget.value?.replace(/[,]+/g, "")))),
                    });
                  }}
                />
              </>
              {errors.areas && (
                <FormHelperText component={Box}>
                  <Alert severity='error' className='custom-alert'>
                    {errors.areas}
                  </Alert>
                </FormHelperText>
              )}
            </div>

            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <TextField
                label={t("PriceFrom")}
                sx={{ width: "50%" }}
                required
                error={Boolean(errors.price)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: { min: 0 },
                  endAdornment: (
                    <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>{t("SAR")}</InputAdornment>
                  ),
                }}
                value={getFormatedPrice((data.priceFrom))}
                size='small'
                onChange={(v) => {
                  setData({
                    ...data,
                    priceFrom: getFormatedPrice(Math.abs(preventString(v.currentTarget.value?.replace(/[,]+/g, "")))),
                  });
                }}
              />
              <TextField
                label={t("PriceTo")}
                required
                error={Boolean(errors.price)}
                sx={{ width: "50%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: { min: 0 },
                  endAdornment: (
                    <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>{t("SAR")}</InputAdornment>
                  ),
                }}
                value={getFormatedPrice(data.priceTo)}
                size='small'
                onChange={(v) => {
                  setData({
                    ...data,
                    priceTo: getFormatedPrice(Math.abs(preventString(v.currentTarget.value?.replace(/[,]+/g, "")))),
                  });
                }}
              />
              {errors.price && (
                <FormHelperText component={Box}>
                  <Alert severity='error' className='custom-alert'>
                    {errors.price}
                  </Alert>
                </FormHelperText>
              )}
            </div>
           
            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <FormControl
                sx={{ width: "100%" }}
                error={Boolean(errors.area)}
              >
                <Autocomplete
                  // disablePortal
                  size='small'
                  required
                  slotProps={{ popper: { sx: { direction: 'rtl', zIndex: 9999999999999 } } }}
                  options={regionsOptions}
                  value={regionsOptions.filter((i) => +i.id === +data?.area)?.[0] || null}
                  sx={{ width: "100%" }}
                  onChange={(v, newValue) => {
                    setData({
                      ...data,
                      area: newValue?.id || "",
                      neighbourhoodIds: [],
                      city: ''
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      required
                      InputLabelProps={{ shrink: true }}
                      error={Boolean(errors.area)}
                      variant='outlined'
                      label={t("Region")}
                    />
                  )}
                />
                {errors.area && (
                  <FormHelperText component={Box}>
                    <Alert severity='error' className='custom-alert'>
                      {errors.area}
                    </Alert>
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <FormControl
                sx={{ width: "100%" }}
                error={Boolean(errors.city)}
              >
                <Autocomplete
                  // disablePortal
                  size='small'
                  slotProps={{ popper: { sx: { direction: 'rtl', zIndex: 9999999999999 } } }}
                  required
                  disabled={!data?.area}
                  options={citiesOptions.filter((i) => +i.regionId === +data?.area)}
                  value={citiesOptions.filter((i) => +i.id === +data?.city)?.[0] || null}
                  sx={{ width: "100%" }}
                  noOptionsText={t("ThereAreNoCitiesAssociatedWithTheSelectedRegion")}
                  onChange={(event, newValue) => setData({ ...data, city: newValue?.id || "", neighbourhoodIds: [] })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      required
                      InputLabelProps={{ shrink: true }}
                      error={Boolean(errors.city)}
                      variant='outlined'
                      label={t("City")}
                    />
                  )}
                />
                {errors.city && (
                  <FormHelperText component={Box}>
                    <Alert severity='error' className='custom-alert'>
                      {errors.city}
                    </Alert>
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <FormControl
                sx={{ width: "100%" }}
                error={Boolean(errors.neighbourhoodIds)}
              >
                <Autocomplete
                  // disablePortal
                  size='small'
                  slotProps={{ popper: { sx: { direction: 'rtl', zIndex: 9999999999999 } } }}
                  required
                  multiple={true}
                  disabled={!data?.city}
                  options={districtsOptions.filter(
                    (i) =>
                      +i.cityId === +data?.city &&
                      +i.regionId === +data?.area
                  )}
                  value={
                    districtsOptions.filter(
                      (i) => data?.neighbourhoodIds?.includes(+i.id)
                    ) || []
                  }
                  sx={{ width: "100%" }}
                  noOptionsText={t("ThereAreNoNeighborhoodsAssociatedWithTheSelectedCity")}
                  onChange={(event, newValue) => {
                    const ids = newValue?.map(i => i.id);
                    setData({ ...data, neighbourhoodIds: ids });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      required
                      InputLabelProps={{ shrink: true }}
                      error={Boolean(errors.neighbourhoodIds)}
                      variant='outlined'
                      label={t("Neighborhoods")}
                    />
                  )}
                />
                {errors.neighbourhoodIds && (
                  <FormHelperText component={Box}>
                    <Alert severity='error' className='custom-alert'>
                      {errors.neighbourhoodIds}
                    </Alert>
                  </FormHelperText>
                )}
              </FormControl>
            </div>
              <div className="col-12 col-md-6 col-lg-4 mb-4">
              <FormControl
                error={Boolean(errors.seriousness)}
 className="custom-select">
                <InputLabel required htmlFor='seriousness'>{t("CustomerSeriousness")}</InputLabel>
                <Select
                  value={data.seriousness}
                  sx={{ width: "100%" }}
                  
                  onChange={(event) => {
                    setData({
                      ...data,
                      seriousness: event.target.value,
                    });
                  }}
                  input={<OutlinedInput
                    notched
                    label={t("CustomerSeriousness")}
                    id="seriousness"
                  />}
                  label={t("CustomerSeriousness")}
                  inputProps={{
                    name: "seriousness",
                    id: "seriousness",
                  }}
                  error={Boolean(errors.seriousness)}>
                  
                  <MenuItem value='منخفضة'>{t("Low")}</MenuItem>
                  <MenuItem value='متوسطة'>{t("Medium")}</MenuItem>
                  <MenuItem value='مرتفعة'>{t("High")}</MenuItem>
                </Select>              
                {errors.seriousness &&
                  <FormHelperText component={Box}>
                    <Alert severity="error" className="custom-alert">
                      {errors.seriousness}
                    </Alert>
                  </FormHelperText>
                }
              </FormControl>
            </div>
            <div className="col-12 mb-4">
          <TextField
              label={t("Notes")}
              sx={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ maxLength: 500 }}
              value={data.notes}
              size='small'
              multiline
              rows={5}
              onChange={(v) => {
                setData({
                  ...data, notes: resolvePersianAndArabicNumbers(
                    v.currentTarget.value?.length >= 500 ? v.currentTarget.value?.substring(0, 500) : v.currentTarget.value
                  )
                });
              }}
            />
            <h6 className='rightalign mt-2'>
              {data.notes
                ? parseInt(data?.notes?.length) + "/500"
                : "0/500"}
            </h6>
          </div>        
         
          </div>
        </div>
      </div>

      <div className="text-center pb-5 d-flex align-items-center justify-content-center" style={{gap:'8px'}}>
        <LoadingButton
          style={{ minWidth: "125px", justifyContent: "center" }}
          label={t("Accept")}
          classes='primary-btn px-5'
          handleClick={()=>openDeleteModal(t("ConfirmApprove"), t("AreYouSureToApproveThisRequest"),
          () => handleSave(), t("Accept"))}
          loading={loadingButton}
        />
         <LoadingButton
          style={{ minWidth: "125px", justifyContent: "center" }}
          label={t("Reject")}
          classes='primary-btn delete-btn px-5'
          handleClick={()=>openDeleteModal(t("ConfirmRejection"), t("AreYouSureToRejectThisRequest"),
          () => handleReject(id), t("Reject"))}
          loading={isSubmittingReject}
        />
      </div>
    </div>
  );
}

export default EditPropertyRequest;
