import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  addFavourite,
  removeFavourite
} from "../../api/propertyApi";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getFontspace, getLocalizedText, renderFeatureIcon, renderFeatureIconCondition, renderRoomIcon, renderRoomIconCondition } from "../../helpers";
import { useAuth } from "../../helpers/context";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { DARK_TEMPLATES, MODERN_TEMPLATES } from "../../constants";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Pagination } from "swiper";
import moment from "moment";
import { useTranslation } from "react-i18next";

const publicUrl = process.env.REACT_APP_URL + "/";

const PropertyBox = (props) => {
  const { t, i18n } = useTranslation();
  const { token, UserId, vendor, templateId } = useAuth();
  const hideHeart = props.hideHeart || false;
  let path = props.isAdmin ? "/admin/property-details/" : props.path || "/property-details/";
  const [Edit] = useState(props.isEdit ? props.isEdit : false);
  const [inFav, setinFav] = useState(props.propertey.isInFavourites);
  const [selected, setSelected] = useState(
    props.isSelected ? props.isSelected : false
  );
  const [loadingFav, setLoadingFav] = useState({ visible: false, id: 0 });

  function handleSelectedChange() {
    if (selected) props.onUnSelect(props.propertey.propertyId);
    else props.onSelect(props.propertey.propertyId);
    setSelected(!selected);
  }

  const [item] = useState(props.propertey);

  const propertyLicenseNumber = item?.additionalInfo?.filter(i => i.key === '10')?.[0]?.valueText;

  function handleFav() {
    setLoadingFav({ visible: true, id: item.propertyId });
    if (inFav === "0") {
      addFavourite({
        propertyId: item.propertyId,
        userId: token,
      })
        .then((property) => {
          if (property.resCode === 0) {
            if (props.onChange) props.onChange();
            else setinFav("1");
          } else {
            toast.error(property.resStr);
          }
        }).finally(() => setLoadingFav({ visible: false, id: 0 }));
    } else {
      removeFavourite({
        propertyId: item.propertyId,
        userId: token,
      })
        .then((property) => {
          if (property.resCode === 0) {
            if (props.onChange) props.onChange();
            else setinFav("0");
          } else {
            toast.error(property.resStr);
          }
        }).finally(() => setLoadingFav({ visible: false, id: 0 }));
    }
  }

 
  const renderInfoList = (item, withBorder = true) => {
    return (
      <ul className='info-list' style={withBorder ? { borderTop: '1px solid #eee', paddingTop: 5 } : {}}>
        {item.features.map((feature, i) => renderFeatureIconCondition(item.propertyType, feature.key)
          && (
            <li key={"li" + i} title={feature.name} style={[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? { display: 'flex', flexDirection: 'column', gap: '7px' } : {}}>
              {[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) && <span style={{ flexDirection:DARK_TEMPLATES.includes(+templateId)?'row-reverse':'row', display: 'flex', gap: 3, fontSize: '12px', color:'var(--paragraph-color)', alignItems:'center' }}>
                {feature.name}
                {renderFeatureIcon(feature.key)}
              </span>}
              <span className="feature-value" style={[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? { fontSize: '16px', fontWeight: 600 } : {}}>
                {feature.key !== '7'
                  ? Intl.NumberFormat("en").format(feature.value) + " " + feature.unit
                  : getFontspace(feature.value)}
              </span>
              {![...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) && renderFeatureIcon(feature.key)}
            </li>
          )
        )}
        {item.rooms.map((room, i) => renderRoomIconCondition(item.propertyType, room.key)
          && (
            <li key={"li-" + i} title={room.name} style={[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? { display: 'flex', flexDirection: 'column', gap: '7px' } : {}}>
              {[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) && <span style={{ flexDirection:DARK_TEMPLATES.includes(+templateId)?'row-reverse':'row', display: 'flex', gap: 3, fontSize: '12px',color:'var(--paragraph-color)', alignItems:'center' }}>
                {room.name}
                {renderRoomIcon(room.key)}
              </span>}
              <span className="feature-value" style={[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? { fontSize: '16px', fontWeight: 600 } : {}}>
                {room.value}
              </span>
              {![...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) && renderRoomIcon(room.key)}
            </li>
          )
        )}
      </ul>
    );
  }

  const renderPrice = (item, withBorder = true) => {
    return (
      propertyLicenseNumber && propertyLicenseNumber !== 'لا' ? (<h3 className='price' style={withBorder ? { borderTop: '1px solid #eee' } : { padding: 0 }}>
        {item.listedFor === "2" ? (
          <span>
            {Intl.NumberFormat("en").format(item.defaultPrice)} {t("SAR")}
          </span>
        ) : (
          <span>
            {Intl.NumberFormat("en").format(item.defaultPrice) +
              ` ${t("SAR")} / ` +
              item.defaultPriceType.name}
          </span>
        )}
      </h3>) :
        (<h3 className='price'>
          <span style={{ fontSize: '13px', color: '#d00404' }}>{t("WaitingForTheADLicenseNumber")}</span>
        </h3>)
    );
  }

  const image = (
    <div className='thumb'>
      {item?.photos?.split(",")?.[0] ? vendor === "amakkn" ?
        <LazyLoadImage
          src={item?.photos?.split(",")?.[0]}
          alt={`${item.propertyTypeName} ${item.listedFor === "1" ? t("ForRent") : t("ForSale")}`}
        /> :
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          key={i18n.language + 'ii'}
          modules={[Keyboard, Pagination]}
          keyboard={{ enabled: true }}
          pagination={{
            clickable: true, el: `.swiper-pagination.pagination-${item.propertyId}`,
            dynamicBullets: true,
            dynamicMainBullets: 4
          }}
          speed={2600}>
          {item.photos.split(",").map((image, index) => (
            <SwiperSlide key={index}>
              <LazyLoadImage
                src={image}
                alt={`${item.propertyTypeName} ${item.listedFor === "1" ? t("ForRent") : t("ForSale")} ${index}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        :
        <LazyLoadImage
          src={`${publicUrl}assets/img/defimgs/${item.propertyType}${DARK_TEMPLATES.includes(+templateId) ? '-dark' : ''}.svg`}
          className={item.photos.split(",")[0] ? '' : 'default-img'}
          alt=''
        />}
      {item?.photos?.split(",")?.[0] && vendor !== "amakkn" ? <div className={`swiper-pagination pagination-${item.propertyId}`}></div> : null}

      {[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) && !Edit && <span>{renderPrice(item, false)}</span>}
    </div>
  );

  const content = (
    <div className="position-relative">
      {item.isFeatured === "1" && Edit && <div className={`featured-ribbon edit`}><span>{t("FeaturedAD")}</span></div>}
      <h6 className='property-name' style={MODERN_TEMPLATES.includes(+templateId) ? { color: '#121212', fontSize: 25 } : DARK_TEMPLATES.includes(+templateId) ? { color: '#fff', fontSize: 25 } : {}}>
        {item.propertyTypeName}{" "}
        {item.listedFor === "1" ? t("ForRent") : t("ForSale")}
      </h6>
      <h6 className='readeal-top one-line-desc'>{item.address}</h6>
      {![...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? renderInfoList(item, false) : Edit && renderPrice(item, false)}
      <h6 className='property-date'>
        {t("Updated")}&nbsp;
        {moment().diff(new Date(item.updatedAt)) <= 47335428000 ? moment(new Date(item.updatedAt)).startOf('second').fromNow() : t("MoreThanYearAgo")}
      </h6>
      
      <h6 className='property-date'>
        {item?.relatedProjectName && item?.relatedProjectName !== "None" ? (
          <> {t("LinkedWith")}:&nbsp;{getLocalizedText(item.relatedProjectName, i18n.language, false)}</>
        ) : <>&nbsp;</>
        }

      </h6>
      {Edit && props.isAssigned && (
        <h6 className='property-date'>
          {t("Agent")}:&nbsp;{item.hostInfo.name}
        </h6>
      )}
      {[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? renderInfoList(item) : renderPrice(item)}
    </div>
  );

  return (
    <div
      className={`single-feature ${props.isAdmin ? 'admin' : ''} ${[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? 'property-box-v2' : ''}`}>
      {token && item.hostInfo.id !== UserId && !props.isAdmin && vendor === 'amakkn' && !hideHeart && (
        <div
          className='left'
          style={{
            position: "absolute",
            zIndex: "10",
            padding: "9px"
          }}
          >
          
          {loadingFav?.visible && loadingFav.id=== item.propertyId ? (
             <svg width="30" className="loading-fav" height="30" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.157 6.31A7.874 7.874 0 1 1 27.3 17.433l-1.913 1.912-9.254 9.254-1.88-1.88-7.373-7.374-1.91-1.91a7.874 7.874 0 1 1 11.137-11.13l.027.025.022-.022z"></path>
            </svg>
          ): inFav === '0' ? (
            <svg width="30" onClick={handleFav} height="30" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
              <g fill="none"><path d="M26.95 11.863a5.193 5.193 0 0 1-1.53 3.69l-1.913 1.912-7.373 7.373-7.371-7.373-1.912-1.912a5.214 5.214 0 1 1 7.377-7.366l1.906 1.907 1.908-1.908a5.214 5.214 0 0 1 8.908 3.677z" fill-opacity=".4" fill="#000"></path><path d="M26.95 11.863a5.214 5.214 0 0 0-8.908-3.677l-1.908 1.908-1.906-1.908a5.214 5.214 0 1 0-7.377 7.366l1.912 1.913 7.371 7.373 7.373-7.373 1.912-1.912a5.193 5.193 0 0 0 1.53-3.69zM16.157 6.31A7.874 7.874 0 1 1 27.3 17.433l-1.913 1.913-9.254 9.254-1.88-1.88-7.373-7.374-1.91-1.91a7.874 7.874 0 1 1 11.137-11.13l.027.025.022-.022z" fill="#FFF"></path></g>
            </svg>
          ) : (
            <svg width="30" onClick={handleFav} height="30" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.157 6.31A7.874 7.874 0 1 1 27.3 17.433l-1.913 1.912-9.254 9.254-1.88-1.88-7.373-7.374-1.91-1.91a7.874 7.874 0 1 1 11.137-11.13l.027.025.022-.022z" fill="#ff5e3f"></path>
            </svg>
          )}
        </div>
      )}

      {Edit && (
        <div className='property-type'>
          <FormControlLabel
            control={
              <Checkbox
                checked={selected}
                onChange={handleSelectedChange}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
            label={t("Check")}
          />
        </div>
      )}
      {item.isFeatured === "1" && !Edit && <div className="featured-ribbon"><span>{t("FeaturedAD")}</span></div>}
      <div onClick={(e) => {
        if (!e?.target?.className?.includes('swiper-pagination-bullet')) {
          if (props.onItemClick)
            props.onItemClick(item.idToShare);
          if (props.onItemSelected)
            props.onItemSelected(item.idToShare);
        }
       
      }}
        style={DARK_TEMPLATES.includes(+templateId) ? { height: '350px' } : {}}
      >
        {props.disableLink ? image : <Link to={path + item.idToShare}>{image}</Link>}
        <div className='details'>
          {props.disableLink ? content : <Link to={path + item.idToShare}>{content}</Link>}
        </div>

      </div>
    </div>
  );
}

export default PropertyBox;
