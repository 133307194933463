import { Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getWlPermission, saveWlPermissions } from '../../../../api/userApi';
import { useAuth } from '../../../../helpers/context';
import md5 from 'md5';
import LoadingButton from '../../../../components/global-components/loading-btn';
import { toast } from 'react-toastify';

export default function ManagePermissionsDialog({ dialog, setDialog, onOK }) {
    const { t } = useTranslation();
    const { token } = useAuth();
    const [checked, setChecked] = React.useState([
        // { key: 'Dashboard', value: false },
        { key: 'Projects', value: false },
        { key: 'RealEstateADs', value: false },
        { key: 'AddAD', value: false },
        { key: 'InterestedCustomers', value: false },
        { key: 'UnlicensedADs', value: false },
        { key: 'VIPADs', value: false },
        { key: 'CustomersList', value: false },
        { key: 'AddCustomer', value: false },
        { key: 'MatchedCustomers', value: false },
        { key: 'Deals', value: false },
        { key: 'Tasks', value: false },
        { key: 'PropertiesRequests', value: false },
        { key: 'Blog', value: false },
        { key: 'Styles', value: false },
        { key: 'Templates', value: false },
        { key: 'HomePage', value: false },
        { key: 'AboutUS', value: false },
        { key: 'Addons', value: false },
        { key: 'MyProfile', value: false },
        { key: 'CustomersMessages', value: false },
        { key: 'AmakknUsersMessages', value: false },
        { key: 'ADsReports', value: false },
        { key: 'CustomerSupport', value: false },
        // { key: 'Settings', value: false }
    ]);

    const [timeStamp, setTimeStamp] = useState(new Date());
    const [isSubmittingData, setIsSubmittingData] = useState(false);

    useEffect(() => {
        if (dialog.open) {
            getWlPermission({
                userId: md5(dialog.createdAt + dialog.id)
            }).then(res => {
                if (res.resCode === 0) {
                    let temp = checked;
                    for (let permission of res.response.permissions) {
                        temp.map((item, index) => {
                            if (item.key === permission) {
                                temp[index].value = true;
                            }
                            return null;
                        });
                    }
                    setChecked(temp);
                    setTimeStamp(new Date());

                }
            });
        } else {
            setChecked([
                { key: 'Projects', value: false },
                { key: 'RealEstateADs', value: false },
                { key: 'AddAD', value: false },
                { key: 'InterestedCustomers', value: false },
                { key: 'UnlicensedADs', value: false },
                { key: 'VIPADs', value: false },
                { key: 'CustomersList', value: false },
                { key: 'AddCustomer', value: false },
                { key: 'MatchedCustomers', value: false },
                { key: 'Deals', value: false },
                { key: 'Tasks', value: false },
                { key: 'PropertiesRequests', value: false },
                { key: 'Blog', value: false },
                { key: 'Styles', value: false },
                { key: 'Templates', value: false },
                { key: 'HomePage', value: false },
                { key: 'AboutUS', value: false },
                { key: 'Addons', value: false },
                { key: 'MyProfile', value: false },
                { key: 'CustomersMessages', value: false },
                { key: 'AmakknUsersMessages', value: false },
                { key: 'ADsReports', value: false },
                { key: 'CustomerSupport', value: false },
                // { key: 'Settings', value: false }
            ]);
        }
    },
        // eslint-disable-next-line
        [dialog, token]);

  
    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={dialog.open}
            className="custom-dialog model"
            onClose={() => {
                setDialog({ ...dialog, open: false });
                onOK?.();
            }}>
            <DialogTitle>
                <div className="dialog-head flex-column flex-md-row">
                    <div>
                        <h2>{t("ManagePermissions")}</h2>
                        <p>{t("YouCanManageAgentPermissions")}</p>
                    </div>
                    <div className="dialog-actions flex-row">
                        <LoadingButton classes="px-3 primary-btn" handleClick={() => {
                            setIsSubmittingData(true);
                            saveWlPermissions({
                                adminId: token,
                                userId: md5(dialog.createdAt + dialog.id),
                                permissions: checked?.filter(i => i.value)?.map(i => i.key)
                            }).then(res => {
                                setDialog({ ...dialog, open: false });
                                toast.success(t("DoneSuccessfully"));
                                onOK?.();
                            }).finally(() => setIsSubmittingData(false));
                        }}
                            loading={isSubmittingData}
                            label={t("Save")}
                        />
                        
                        <button className="px-3 outlined-btn" onClick={() => {
                            setDialog({ ...dialog, open: false });
                            onOK?.();
                        }}>
                            {t("Cancel")}
                        </button>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div>
                    <FormControlLabel
                        label={t("All")}
                        sx={{ marginInlineStart: -2 }}
                        control={
                            <Checkbox
                                checked={checked?.filter(i => !i.value)?.length === 0}
                                indeterminate={checked?.filter(i => !i.value)?.length > 0 && checked?.filter(i => i.value)?.length > 0}
                                onChange={(e) => {
                                    let temp = checked;
                                    temp?.map(i => {
                                        i.value = e.target.checked;
                                        return null;
                                    });
                                    setChecked(temp);
                                    setTimeStamp(timeStamp + new Date())
                                }}
                            />
                        }
                    />
                    <div className='row mx-0'>
                        {checked?.map((item) => {
                            return (
                                <div key={item.key} className='col-12 col-lg-4'>
                                    <FormControlLabel
                                        label={t(item.key)}
                                        control={
                                            <Checkbox
                                                checked={item.value}
                                                onChange={(e) => {
                                                    let temp = checked;
                                                    temp?.map((i, index) => {
                                                        if (i.key === item.key)
                                                            temp[index].value = e.target.checked;
                                                        return null;
                                                    });
                                                    setChecked(temp);
                                                    setTimeStamp(timeStamp + new Date())
                                                 
                                                }}
                                            />
                                        }
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
