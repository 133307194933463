import React, { useEffect, useState } from "react";
import PasswordForm from "./PasswordForm";
import * as userApi from "../../../api/userApi";
import { toast } from "react-toastify";
import md5 from "md5";
// import { logEvent } from "firebase/analytics";
// import { analytics } from "../../../Config/Firebase";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppLayout from "../../layouts/app-layout";
import { useAuth } from "../../../helpers/context";
import { useTranslation } from "react-i18next";

const publicUrl = process.env.REACT_APP_URL + "/";

const SetPassword = (props) => {
  const [errors, setErrors] = useState({});
  let location = useLocation();
  // const [FireBaseToken, setFireBaseToken] = useState();
  const { setToken, setUserId, vendor } = useAuth();
  const history = useNavigate();
  const [disable, setDisable] = React.useState(false);
  const { countryCode, phone } = useParams();
  const { t, i18n } = useTranslation();
  const [user, setUser] = useState({
    id: null,
    password: "",
    confirmpassword: "",
    countryCode: countryCode,
    phone: phone,
    language: i18n.language === "ar" ? "0" : "1",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  // useEffect(() => {
  //   getToken(setFireBaseToken);
  // }, []);

  function handleChange({ target }) {
    setUser({
      ...user,
      [target.name]: target.value,
    });
  }

  useEffect(
    () => {
      if (Object.keys(errors).length > 0) {
        formIsValid();
      }
    }, // eslint-disable-next-line
    [i18n.language]
  );

  function formIsValid() {
    const _errors = {};

    if (!user.password) _errors.password = t("EnterThePassword");
    if (!user.confirmpassword)
      _errors.confirmpassword = t("ReEnterThePassword");

    if (user.password !== user.confirmpassword)
      _errors.confirmpassword = t("PasswordsDoNotMatch");

    setErrors(_errors);
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (!formIsValid()) return;
    setDisable(true);
    setIsSubmitting(true);
    userApi
      .setPasswordForUser(user)
      .then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          const userId =
            _userInfo.response.user.createdAt + _userInfo.response.user.userId;

          let userHashed = md5(userId);
          if (_userInfo.response.user.userType === "1") {
            // logEvent(analytics, "normal_user_registration", {
            //   platform: "website",
            // });
          }
          if (vendor === "amakkn") {
            setToken(userHashed);
            setUserId(_userInfo.response.user.userId);

            // if (FireBaseToken)
            //   userApi.setPushTokenForUserNew({
            //     userId: userHashed,
            //     pushToken: FireBaseToken,
            //     platform: "website",
            //     language: i18n.language === 'ar' ? '0' : '1',
            //     identifier: "-1",
            //   });

            history("/success/" + _userInfo.response.user.userType, {
              state: { from: location.state.from },
            });
          } else {
            history("/admin/agents/");
          }

          toast.success(_userInfo.resStr);
        } else {
          const _errors = {};
          _errors.password = _userInfo.resStr;
          setErrors(_errors);
          setDisable(false);
        }
      })
      .finally(() => setIsSubmitting(false));
  }

  return (
    <AppLayout pageTitle={t("SetPassword")} withoutNav={vendor !== "amakkn"}>
      <div
        className={`${
          vendor !== "amakkn" ? "admin" : ""
        } page-wrapper page-width`}>
        <div className='row page-wrapper-without-top'>
          <div className='col-12 col-lg-6 details-wrapper scrolled'>
            <PasswordForm
              errors={errors}
              user={user}
              onChange={handleChange}
              onSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              disable={disable}
            />
          </div>
          <div
            className='col-12 d-none d-lg-flex col-lg-6 bg-cover py-5 py-lg-0'
            style={{
              background: `url(${publicUrl}assets/img/pattern.png) repeat`,
            }}>
            <div className='img-container'>
              <img
                src={publicUrl + "assets/img/others/others/2.png"}
                alt='whitelabel'
              />
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default SetPassword;
